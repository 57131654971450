import { css } from "@emotion/react";
import React, { useState } from "react";
import Content from "../Components/Atoms/Content";
import ContentBox from "../Components/Atoms/ContentBox";
import Detail from "../Components/Atoms/Detail";
import Input from "../Components/Atoms/Input";
import FeatureBtn from "../Components/Organisms/FeatureBtn";
import { fontSizePalette } from "../Styles/theme";
import ComContext, { IComProps } from "../Context/Com";
import useOTC, { OtcCreateType } from "../Hooks/useOtc";

import { PageType } from "./OtcToken";
import ProcessItems from "../Components/Molecules/ProcessItems";

import WarningMsg from "../Components/Atoms/WarningMsg";
import { waringProps } from "../Components/Organisms/FeverStaking";

interface Iprops {
  connectOnClick: () => void;
  setOtcPage: React.Dispatch<React.SetStateAction<number>>;
  setTab: React.Dispatch<React.SetStateAction<string>>;
  setType: React.Dispatch<React.SetStateAction<string>>;
}

const OtcFind: React.FC<Iprops> = ({ setType, setTab, connectOnClick, setOtcPage }) => {
  const comContext = React.useContext<IComProps | null>(ComContext);
  const [address, setAddress] = useState<string>("");

  const [isMinWaring, setMinWaring] = useState<waringProps>({
    state: false,
    title: "",
  });
  const { getOtc, otcState } = useOTC();
  const [process, setProcess] = useState({
    loading: false,
    title: "Find",
  });
  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => setAddress(e.target.value);

  const otcOnClick = async (e: React.ChangeEvent<HTMLButtonElement>) => {
    if (address.toLocaleLowerCase() !== comContext?.state.account.toLocaleLowerCase()) {
      setProcess((prev: any) => ({ ...prev, loading: true }));
      if (process.title === "Find") {
        const res = await getOtc(address);
        //console.log("res", res);
        if (res === OtcCreateType.ALREADY) {
          setTab("pending");
          setType("Find");
          setOtcPage(PageType.DEPOSIT_PAGE);
        } else if (res === 999) {
          setMinWaring({ state: true, title: "Please enter the correct Address" });
        } /*else if (res === OtcCreateType.COMPLEATE) {
        setTab("complete");
        setOtcPage(PageType.COMPLETE_PAGE);
      }*/ else {
          setOtcPage(PageType.CREATE_PAGE);
        }
      }
    } else {
      setMinWaring({ state: true, title: "Enter the wallet address of other party." });
    }
  };
  return (
    <>
      <ProcessItems processNum={comContext?.state.account === "" ? "1" : "2"} addStyle="margin: 15px 0;" />
      <Content addStyle="padding: 20px;">
        <div
          css={css`
            ${fontSizePalette.base}
          `}
          style={{ width: "100%", textAlign: "center", margin: "10px 0" }}
        >
          Find Trading Room
        </div>
        <Detail title="Other User Address" />
        <ContentBox>
          <Input disabled={comContext?.state.account === ""} fontSize={fontSizePalette.base} onChange={onChange} placeholder="Address" value={address} />
        </ContentBox>

        <WarningMsg state={isMinWaring.state} title={isMinWaring.title} />
        <FeatureBtn title="Find" loading={false} connectOnClick={connectOnClick} featureOnClick={otcOnClick} />
      </Content>
    </>
  );
};

export default OtcFind;
