import React, { useEffect, useState } from "react";
import BurnContract, { IBurnState } from "../Models/BurnContract";
import ComContext, { IComProps } from "../Context/Com";

const burnContract = new BurnContract();
const burnInitial = {
  myGen: 0,
  myStGen: 0,
  inputValue: 0, //? 보류...
};
function useBurn() {
  const comContext = React.useContext<IComProps | null>(ComContext);
  const [burnState, setBurnState] = useState<IBurnState>(burnInitial);

  const approve = async (inputValue: number) => {
    setBurnState((prev: IBurnState) => ({ ...prev, inputValue }));
    return await burnContract.approve(inputValue);
  };
  const convert = async () => {
    const res = await burnContract.convert();
    if (res) {
      setBurnState((prev: IBurnState) => ({ ...prev, inputValue: 0 }));
      return true;
    } else return false;
  };

  const readtData = async () => {
    const result = await burnContract.connectContract();
    if (result) {
      const res = await burnContract.readBurn();
      return res;
    }
  };

  const saveFn = async () => {
    const res = await readtData();
    if (res) setBurnState((prev: IBurnState) => ({ ...prev, ...res }));
  };

  useEffect(() => {
    if (comContext?.state.account !== "") {
      saveFn();
      const timeId = setInterval(() => {
        saveFn();
      }, 3000);
      return () => clearInterval(timeId);
    } else {
      setBurnState(burnInitial);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [comContext?.state.account]);

  //changenetwork
  window.ethereum.on("networkChanged", (e: string) => {
    burnContract.connectContract();
  });
  return {
    readtData,
    burnState,
    approve,
    convert,
  };
}

export default useBurn;
