import { css } from "@emotion/react";
import React, { useEffect, useState } from "react";

import { fontBoldStyle, fontSizePalette, hoverStyle, palette } from "../../Styles/theme";
import Detail from "../Atoms/Detail";
import Input from "../Atoms/Input";
import ConnectWallet from "../Molecules/ConnectWallet";
import PerCentButtons from "../Molecules/PerCentButtons";
import ComContext, { IComProps } from "../../Context/Com";
import useStaking from "../../Hooks/useStaking";
import { numDigit } from "../../Utils/util";
import Content from "../Atoms/Content";
import Loading from "../Atoms/Loading";
import ContentBox from "../Atoms/ContentBox";
import Button from "../Atoms/Button";
import FeatureBtn from "./FeatureBtn";
import Odom from "../Atoms/Odom";
import useNetwork from "../../Hooks/useNetwork";
import WarningMsg from "../Atoms/WarningMsg";
import { toast } from "react-toastify";

interface Iprops {
  connectOnClick: () => void;
}

const btnStyle = `
margin: 0 10px;
height: 25px;
background-color: ${palette.gray};
${hoverStyle}
`;

const featureBtnStyle = `
margin: 0 10px;
height: 25px;
background-color: ${palette.blue200};
${hoverStyle}
`;

export interface waringProps {
  state: boolean;
  title: string;
}

const FeverStaking: React.FC<Iprops> = ({ connectOnClick }) => {
  const comContext = React.useContext<IComProps | null>(ComContext);
  const { stState, approve, stake, claim, unstake, initStState, saveFn } = useStaking("stgen");
  const [clickNum, setClickNum] = useState<string>("");
  const [amount, setAmount] = useState<string>("0");
  const { change } = useNetwork();
  const [isMinWaring, setMinWaring] = useState<waringProps>({
    state: false,
    title: "",
  });
  const [process, setProcess] = useState({
    approveResult: false,
    target: "",
    loading: false,
  });

  const [iscss, setCss] = useState({
    title: "",
  });

  useEffect(() => {
    setAmount(String(+stState.myGen * (Number(clickNum) / 100)));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clickNum]);

  useEffect(() => {
    if (comContext?.state.account === "") {
      setAmount("");
      setCss({ title: "Connect Wallet" });
    } else if (window.ethereum && window.ethereum.chainId !== "0x66eed") {
      setCss({ title: "Arbitrum Network Change" });
    } else {
      setCss({
        title: "Approve",
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [comContext?.state.account]);

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setAmount(e.target.value);
  };
  const stakingOnClick = async (e: React.ChangeEvent<HTMLButtonElement>) => {
    if (amount === "" || +amount > +stState.myGen) {
      setMinWaring({ state: true, title: "Please check your stGEN balance" });
    } else if (Number(stState.genTotalStaked) < 100) {
      setMinWaring({ state: true, title: "You must stake a minimum of 100 GEN in GEN Staking to participate in Fever Staking." });
    } else if (window.ethereum && window.ethereum.chainId === "0x66eed") {
      setMinWaring({ state: false, title: "" });
      if (e.target.id !== "" && e.target.id !== undefined) {
        setProcess((prev: any) => ({ ...prev, target: e.target.id }));
      }
      setProcess((prev: any) => ({ ...prev, loading: true }));
      if (!process.approveResult) {
        const res = await approve(+amount);
        if (res) {
          toast.success("Transaction successful!");
          setProcess((prev: any) => ({ ...prev, approveResult: true, loading: false }));
        } else {
          toast.error("Fail Approve");
          setProcess((prev: any) => ({ ...prev, approveResult: false, loading: false }));
        }
      } else if (process.approveResult) {
        const res = e.target.id === "Stake" ? await stake() : amount !== "" && (await unstake(+amount));
        if (res) {
          setAmount("");
          setProcess((prev: any) => ({ ...prev, approveResult: false, loading: false }));
          setCss({ title: "Approve" });
          toast.success("Transaction successful!");
        } else {
          setProcess((prev: any) => ({ ...prev, loading: false }));
          toast.error(`Transaction failed`);
        }
      } else setProcess((prev: any) => ({ ...prev, loading: false }));
      //}
    } else {
      await change("Arbitrum");
    }
  };

  useEffect(() => {
    if (window.ethereum) {
      initStState();
      saveFn();
      window.ethereum.on("networkChanged", (e: string) => {
        if (e === "421613") setCss({ title: "Approve" });
        else setCss({ title: "Arbitrum Network Change" });

        setMinWaring({ state: false, title: "" });
      });
    }
  }, []);

  const claimOnClick = async () => {
    const res = await claim();
    if (res) toast.success(`Transaction successful!`);
    else toast.error(`Transaction failed`);
  };

  const featureArr = [
    { title: "Stake", btnStyle: `${featureBtnStyle}   cursor: ${!process.loading ? "pointer" : "wait"}` },
    { title: "Unstake", btnStyle: `${btnStyle} cursor: ${!process.loading ? "pointer" : "wait"}` },
  ];

  return (
    <>
      <div style={{ padding: "30px 70px" }}>
        <Detail title="APR" content={`${stState.apr && stState.apr.toFixed(4) + "%"}`} />
        <Detail title="Total Staked" content={`${Number(stState.totalStaked).toFixed(4)} stGEN`} />
      </div>

      <Content>
        {comContext?.state.account !== "" && (
          <div style={{ position: "absolute", width: "100%", top: 20 }}>
            <div style={{ padding: "0px 70px" }}>
              <Detail title="My Staked" content={`${stState.myStaked} stGEN`} />
              <Detail title="Wallet Balance" content={`${numDigit(stState.myGen)} stGEN`} />
            </div>
          </div>
        )}

        <ContentBox name="stGEN">
          <Input disabled={comContext?.state.account === ""} fontSize={fontSizePalette.base} type="Number" onChange={onChange} value={amount} />
        </ContentBox>

        <PerCentButtons inputNum={setClickNum} style={{ padding: "0px 30px" }} />
        {!process.approveResult && <FeatureBtn loading={process.loading} title={iscss.title} connectOnClick={connectOnClick} featureOnClick={stakingOnClick} />}
        {process.approveResult && (
          <div style={{ display: "flex", width: "100%", justifyContent: "center", boxSizing: "border-box", textAlign: "center", padding: "20px 30px" }}>
            {process.approveResult &&
              featureArr.map((data, index) => (
                <ConnectWallet
                  key={index}
                  disabled={process.loading}
                  onClick={stakingOnClick}
                  id={data.title}
                  title={process.target === data.title && process.loading ? "" : data.title}
                  addStyle={data.btnStyle}
                >
                  {process.target === data.title && process.loading && <Loading />}
                </ConnectWallet>
              ))}
          </div>
        )}

        <WarningMsg state={isMinWaring.state} title={isMinWaring.title} />
      </Content>
      {comContext?.state.account !== "" && (
        <>
          <div
            css={css`
              ${fontBoldStyle}
              ${fontSizePalette.lg}
            `}
            style={{ margin: "20px 0", paddingLeft: "70px" }}
          >
            Rewards
          </div>
          <Content style={{ padding: "20px 30px", boxSizing: "border-box", display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
            <Odom num={numDigit(stState.reward + "")} rightTitle="GEN" />
            <Button title="Claim" style={{ padding: "5px", color: "black", backgroundColor: palette.mint }} onClick={claimOnClick} />
          </Content>
        </>
      )}
    </>
  );
};

export default FeverStaking;
