import { css } from "@emotion/react";
import React, { useEffect, useState } from "react";
import { fontBoldStyle, fontSizePalette, palette } from "../../Styles/theme";
import LinkTag from "../Atoms/LinkTag";
interface Iprops {
  link: string;
  title: string;
  onClick?: (title: string) => void;
  click: string;
}

const defaultStyle = `
  width: 100%;
  text-decoration: none;
  text-align: center;
  
  &:hover {
    color:${palette.mint};
  }
  position: relative; 
  display: block;
  box-sizing: border-box;
`;

const hoverFontStyle = `
  color:${palette.mint};
`;

const hoverStyle = `
  background-color: ${palette.mint};
  width: 8px;
  height: 8px;
  border-radius: 4px;
`;

const HeaderItem: React.FC<Iprops> = ({ link, title, click, onClick }) => {
  const [over, setOver] = useState<boolean>(false);
  return (
    <LinkTag
      title={title}
      link={link}
      click={click}
      onClick={() => (onClick ? onClick(title) : "")}
      addStyle={`${defaultStyle}
      ${click === title ? hoverFontStyle : ""}
      ${fontBoldStyle}
      ${fontSizePalette.sm}`}
      onMouseOver={() => setOver(true)}
      onMouseLeave={() => setOver(false)}
    >
      {(over || click === title) && (
        <div style={{ position: "absolute", top: -11, width: "100%", display: "flex", justifyContent: "center" }}>
          <div
            css={css`
              ${hoverStyle}
            `}
          />
        </div>
      )}
    </LinkTag>
  );
};

export default HeaderItem;
