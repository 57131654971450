import { css } from "@emotion/react";
import React from "react";
import { flexCenter } from "../../Styles/theme";

const centerStyle = `
  height: 100%;
  width: 100%;
  ${flexCenter}
  z-index: 0;
  overflow: auto;
`;

const sizeStyle = `
  ${flexCenter}
  flex-dicrection: column;
  width: 600px;
  height: 100%;
  margin: 0;
  padding :0; 
`;

const defaultSyle = `
    width:100%;
    height:100%;

`;

interface Iprops {
  children: React.ReactNode;
  addStyle?: string;
  [key: string]: any;
}

const ContentFrame: React.FC<Iprops> = ({ children, addStyle, ...props }) => {
  return (
    <div
      css={css`
        ${centerStyle}
      `}
      {...props}
    >
      <div
        css={css`
          ${sizeStyle}
        `}
      >
        <div
          css={css`
            ${defaultSyle}
            ${addStyle}
          `}
        >
          {children}
        </div>
      </div>
    </div>
  );
};

export default ContentFrame;
