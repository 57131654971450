import { css } from "@emotion/react";
import React from "react";
import { flexCenter, palette } from "../../Styles/theme";
import ProcessItem from "../Atoms/ProcessItem";

const defaultStyle = `
width: 100%;
height: 50px;
${flexCenter}
`;
const boxStyle = `
width: 400px;
display: flex;
flex-direction: row;
align-items: center;
position: relative;
`;
const lineStyle = `
width: 100%;
height:2px;
position: absolute;

`;

interface Iprops {
  addStyle?: string;
  processNum: string;
}

const ProcessItems: React.FC<Iprops> = ({ addStyle, processNum }) => {
  const processArr = [
    { pos: "left", id: 1 },
    { pos: "center", id: 2 },
    { pos: "right", id: 3 },
  ];

  return (
    <div
      css={css`
        ${defaultStyle}
        ${addStyle}
      `}
    >
      <div
        css={css`
          ${boxStyle}
        `}
      >
        <div
          css={css`
            ${lineStyle}
            background: linear-gradient(90deg,  ${palette.mint} ${+processNum === 1 ? 5 : +processNum === 2 ? 50 : 100}%, white ${+processNum === 1
              ? 50
              : +processNum === 2
              ? 50
              : 0}%);
          `}
        />
        {processArr.map((data, index) => (
          <ProcessItem key={index} pos={data.pos} color={data.id === +processNum ? palette.mint : "white"} />
        ))}
      </div>
    </div>
  );
};

export default ProcessItems;
