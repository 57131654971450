import { css } from "@emotion/react";
import React, { useEffect, useState } from "react";
import Content from "../Components/Atoms/Content";
import ContentBox from "../Components/Atoms/ContentBox";
import Detail from "../Components/Atoms/Detail";
import Input from "../Components/Atoms/Input";
import ProcessItems from "../Components/Molecules/ProcessItems";
import ComContext, { IComProps } from "../Context/Com";
import { PageType } from "./OtcToken";
import PerCentButtons from "../Components/Molecules/PerCentButtons";
import FeatureBtn from "../Components/Organisms/FeatureBtn";
import DropDown from "../Components/Molecules/DropDown";
import useOTC from "../Hooks/useOtc";
import SearchContractModal from "../Components/Organisms/SearchContractModal";
import { numDigit } from "../Utils/util";
import { fontSizePalette } from "../Styles/theme";
import { OtcTranseType } from "../Models/OtcContrax";
import WarningMsg from "../Components/Atoms/WarningMsg";

import { toast } from "react-toastify";

interface Iprops {
  connectOnClick: () => void;
  setOtcPage: React.Dispatch<React.SetStateAction<number>>;
  setTab: React.Dispatch<React.SetStateAction<string>>;
  setType: React.Dispatch<React.SetStateAction<string>>;
}

export interface Istate {
  sellTitle: string;
  sellContract: string;
  sellTotalAmount: number;
  sellAmount: string; // number
  buyTitle: string;
  buyContract: string;
  buyTotalAmount: number;
  buyAmount: string; // number
  buyAccount: string;
}
interface IModal {
  sell: boolean;
  buy: boolean;
}
const initialModalState = {
  sell: false,
  buy: false,
};

export const initialState = {
  sellTitle: "",
  sellContract: "",
  sellTotalAmount: 0,
  sellAmount: "",
  buyTitle: "",
  buyContract: "",
  buyTotalAmount: 0,
  buyAmount: "",
  buyAccount: "",
};

const btnStyle = `
all: unset;
wdith: 100%;
padding: 10px 0;
cursor:pointer;
&:hover{
  background-color:#061753;
}
`;

const OtcCreate: React.FC<Iprops> = ({ setType, connectOnClick, setTab, setOtcPage }) => {
  const comContext = React.useContext<IComProps | null>(ComContext);
  const { createOtc, otcState } = useOTC();
  const [clickNum, setClickNum] = useState<string>("");
  const [state, setState] = useState<Istate>(initialState);
  const [msg, setMsg] = useState<boolean>(false);
  const [isModal, setModal] = useState<IModal>(initialModalState);
  const [loading, setLoading] = useState<boolean>(false);

  const sellonChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setState((prev: Istate) => ({ ...prev, sellAmount: e.target.value }));
  };
  const buyonChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setState((prev: Istate) => ({ ...prev, buyAmount: e.target.value }));
  };

  useEffect(() => {
    if (comContext?.state.account === "") setOtcPage(PageType.FIND_PAGE);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [comContext?.state.account]);

  useEffect(() => {
    console.log("state ", state);
  }, [state]);

  const searchSellOnClick = (sellTitle: string, sellContract: string, sellTotalAmount: number) => {
    setState((prev: Istate) => ({ ...prev, sellTitle, sellContract, sellTotalAmount }));
    setModal((prev: IModal) => ({ ...prev, sell: false }));
  };
  const searchBuyOnClick = (buyTitle: string, buyContract: string, buyTotalAmount: number) => {
    setState((prev: Istate) => ({ ...prev, buyTitle, buyContract, buyTotalAmount }));
    setModal((prev: IModal) => ({ ...prev, buy: false }));
  };
  const otcOnClick = async () => {
    if (state.buyTitle !== "" && state.sellTitle !== "" && state.buyAmount !== "" && state.sellAmount !== "") {
      setMsg(false);
      setLoading(true);

      const data = await createOtc("OTC_TYPE_TOKEN", state.sellContract, +state.sellAmount, state.buyContract, +state.buyAmount);

      if (data) {
        toast.success("Transaction successful!");
        setTab("pending");
        setOtcPage(PageType.DEPOSIT_PAGE);
      } else {
        toast.error("Transaction failed");
        setLoading(false);
      }
    } else {
      setMsg(true);
      toast.error("Transaction failed");
    }
  };

  useEffect(() => {
    //console.log("ddd ", state.sellTotalAmount, String(+state.sellTotalAmount * (Number(clickNum) / 100)));
    setState((prev: Istate) => ({ ...prev, sellAmount: String(+state.sellTotalAmount * (Number(clickNum) / 100)) }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clickNum]);
  /*
  useEffect(() => {
    if (otcState.status === OtcTranseType.ALREADY) {
      setTab("pending");
      setOtcPage(PageType.DEPOSIT_PAGE);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [otcState]);
*/

  return (
    <>
      {/*searchModal && (
        <div
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            zIndex: "100",
          }}
        >
          <div style={{ width: "200px", height: "200px", backgroundColor: "purple" }}>ddd</div>
        </div>
      )*/}
      {isModal.sell && <SearchContractModal closeOnClick={() => setModal((prev: IModal) => ({ ...prev, sell: false }))} searchOnClick={searchSellOnClick} />}
      {isModal.buy && <SearchContractModal closeOnClick={() => setModal((prev: IModal) => ({ ...prev, buy: false }))} searchOnClick={searchBuyOnClick} />}
      <ProcessItems processNum="3" addStyle="margin: 15px 0;" />

      <Content addStyle="padding: 20px;">
        <div
          css={css`
            ${fontSizePalette.base}
          `}
          style={{ width: "100%", textAlign: "center", margin: "10px 0" }}
        >
          Create Trading Room
        </div>
        <Detail title="Sell Crypto" />
        <DropDown
          modalOnClick={() => setModal((prev: IModal) => ({ ...prev, sell: true }))}
          title={state.sellTitle !== "" ? state.sellTitle : "Select Tokens"}
        />
        <Detail title="Wallet Balance" content={`${numDigit(state.sellTotalAmount + "")} ${state.sellTitle}`} />
        <ContentBox name={state.sellTitle}>
          <Input
            disabled={comContext?.state.account === ""}
            onChange={sellonChange}
            value={state.sellAmount}
            fontSize={fontSizePalette.base}
            type="Number"
            placeholder="Amount"
          />
        </ContentBox>
        <PerCentButtons inputNum={setClickNum} style={{ padding: "0px 30px" }} />

        <div style={{ height: "50px" }} />
        <Detail title="Buy Crypto" />
        <DropDown modalOnClick={() => setModal((prev: IModal) => ({ ...prev, buy: true }))} title={state.buyTitle !== "" ? state.buyTitle : "Select Tokens"} />

        <ContentBox name={state.buyTitle}>
          <Input
            disabled={comContext?.state.account === ""}
            onChange={buyonChange}
            value={state.buyAmount}
            fontSize={fontSizePalette.base}
            type="Number"
            placeholder="Amount"
          />
        </ContentBox>

        <WarningMsg state={msg} title="Please make sure the information is correct" />
        <FeatureBtn title="Create" loading={loading} connectOnClick={connectOnClick} featureOnClick={otcOnClick} />
      </Content>
    </>
  );
};

export default OtcCreate;
