import React from "react";
import { modalProps } from "../../Pages/Header";
import { palette } from "../../Styles/theme";

import ConnectButtons from "../Molecules/ConnectButtons";

interface Iprops {
  logOutOnClick: () => void;
  setModal: React.Dispatch<React.SetStateAction<modalProps>>;
}

const AccountModal: React.FC<Iprops> = ({ setModal, logOutOnClick }) => {
  return (
    <div
      style={{
        position: "absolute",

        left: 0,
        right: 0,
        width: "150px",
        padding: "5px 0",
        margin: "10px auto",
        borderRadius: "30px",
        backgroundColor: `rgba( 255, 255, 255, 0.1 )`,
        display: "flex",
        justifyContent: "center",
      }}
    >
      <ConnectButtons setModal={setModal} logOutOnClick={logOutOnClick} />
    </div>
  );
};
export default AccountModal;
