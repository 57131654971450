import { css } from "@emotion/react";
import React from "react";
import { fontBoldStyle, fontThinStyle } from "../../Styles/theme";

interface Iprops {
  title?: string;
  content?: string;
  fontSize?: string;
  leftAddStyle?: string;
  rightAddStyle?: string;
  rightOnclick?: () => void;
  [key: string]: any;
}

const defaultSyle = `
  padding: 10px 0;
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

const Detail: React.FC<Iprops> = ({ title, content, fontSize, leftAddStyle, rightAddStyle, rightOnclick, ...props }) => {
  return (
    <div
      css={css`
        ${defaultSyle}
      `}
      {...props}
    >
      <span
        css={css`
          ${fontBoldStyle}
          ${fontSize}
          ${leftAddStyle}
        `}
      >
        {title}
      </span>
      <span
        css={css`
          ${fontThinStyle}
          ${fontSize}
          ${rightAddStyle}
        `}
        onClick={rightOnclick}
      >
        {content}
      </span>
    </div>
  );
};

export default Detail;
