import React, { useEffect, useState } from "react";
import ConnectWallet from "../Molecules/ConnectWallet";
import { ReactComponent as MoreBtn } from "../../Assets/moreBtn.svg";
import ComContext, { IComProps } from "../../Context/Com";
import { fontSizePalette, palette } from "../../Styles/theme";
import { css } from "@emotion/react";

interface Iprops {
  onClick: () => void;
  state: boolean;
  title: string;
}

const NetworkBtn: React.FC<Iprops> = ({ onClick, state, title }) => {
  const comContext = React.useContext<IComProps | null>(ComContext);
  const [widthResize, setWidthResize] = useState(window.innerWidth);
  useEffect(() => {
    window.addEventListener("resize", () => {
      setWidthResize(window.innerWidth);
    });
    return () => window.removeEventListener("resize", () => {});
  }, []);

  return (
    <div
      style={{
        display: "flex",
        boxSizing: "border-box",
        width: widthResize < 768 ? "150px" : "230px",
        justifyContent: "center",
        alignItems: "center",
        padding: "0 10px",
      }}
    >
      {comContext?.state.account !== "" && (
        <ConnectWallet onClick={onClick} title={title} style={{ backgroundColor: palette.mint }}>
          <MoreBtn style={{ paddingLeft: "10px", transform: `${state ? "rotateX(180deg)" : "rotateX(0deg)"}` }} />
        </ConnectWallet>
      )}
    </div>
  );
};

export default NetworkBtn;
