import { css } from "@emotion/react";
import React from "react";
import { flexCenter, fontBoldStyle, fontSizePalette, noneHoverStyle } from "../../Styles/theme";
import Button from "./Button";

const defaultStyle = `
padding: 0 30px;
position: relative;
width: 100%;
height: 64px;
margin: 20px 0;
${flexCenter}
`;

interface Iprops {
  children: React.ReactNode;
  dropDownItems?: React.ReactNode;
  name?: string;
  rightTag?: React.ReactNode;
  onClick?: (e: React.ChangeEvent<HTMLButtonElement>) => void;
  [key: string]: any;
}

const ContentBox: React.FC<Iprops> = ({ onClick, dropDownItems, children, name, rightTag, ...props }) => {
  const contents = () => {
    return (
      <>
        {children}
        <div
          style={{
            position: "absolute",
            top: 0,
            right: 50,
            height: "100%",
            display: "flex",
            alignItems: "center",
            boxSizing: "border-box",
          }}
        >
          {rightTag ? (
            rightTag
          ) : (
            <span
              css={css`
                ${fontBoldStyle}
                ${fontSizePalette.lg}
              `}
            >
              {name}
            </span>
          )}
        </div>
      </>
    );
  };

  return (
    <>
      {onClick ? (
        <div style={{ width: "100%", position: "relative", padding: "10px 0", boxSizing: "border-box" }}>
          <Button addStyle={`${defaultStyle}${noneHoverStyle}`} onClick={onClick} {...props}>
            {contents()}
          </Button>
          {dropDownItems}
        </div>
      ) : (
        <div
          css={css`
            ${defaultStyle}
          `}
          onClick={onClick}
          {...props}
        >
          {contents()}
        </div>
      )}
    </>
  );
};

export default ContentBox;
