import { css } from "@emotion/react";
import { motion, useCycle } from "framer-motion";
import React, { useEffect, useState } from "react";
import { fontSizePalette } from "../../Styles/theme";

const characters = "1234567890";
const generateString = (length: number) => {
  let result = "";
  const charactersLength = characters.length;

  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }

  return result;
};
// align-items: center;
const defaultStyle = `
  width: 100%;
  display:flex;
  justify-content: flex-start;
  align-items: center;
  
`;

// width:18px; <- 간격
const itemStyle = `
  display: inline-block;
  writing-mode: vertical-rl;
  text-orientation: upright;
  vertical-align: top;
  font-size: 24px;
  
`;

const odomStyle = `
  overflow: hidden;
  height: 32px;
`;

interface Iprops {
  num: string;
  rightTitle?: string;
  leftTitle?: string;
}

const Odom: React.FC<Iprops> = ({ num, leftTitle, rightTitle }) => {
  const [cycleSpin, setCycleSpin] = useCycle("initial", "spin");
  const [odometerLetters, setOdometerLetters] = useState<string[]>([]);
  const [odomNumber, setOdomNumber] = useState<string>(num);

  const variants = {
    spin: {
      y: "calc(-100% + 24px)",
    },
    initial: {
      y: "calc(0% + 0px)",
    },
  };

  useEffect(() => {
    setCycleSpin();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [odomNumber]);

  useEffect(() => {
    setOdomNumber(num);

    const letterArray = odomNumber.split("");
    const odometerArray: string[] = [];

    letterArray.forEach((letter, index) => {
      if (letter !== ",") {
        let letters = letter + generateString((index + 1) * 3) + letter;

        odometerArray.push(letters);
      } else {
        let letters = (index + 1) * 3,
          lettersArr = [];
        for (let i = 0; i < letters - 1; i++) lettersArr[i] = ",";

        lettersArr.push(",");
        odometerArray.push(lettersArr.join());
      }
    });

    setOdometerLetters(odometerArray);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [num, odomNumber]);

  return (
    <div
      css={css`
        ${defaultStyle}
      `}
    >
      {leftTitle && (
        <div
          css={css`
            ${fontSizePalette.lg}
          `}
          style={{ marginLeft: "10px", marginBottom: "5px" }}
        >
          {leftTitle}
        </div>
      )}

      <div
        css={css`
          ${odomStyle}
        `}
      >
        {odometerLetters.map((letter, index) => {
          return (
            <motion.div
              key={index}
              variants={variants}
              initial="initial"
              animate={cycleSpin}
              css={css`
                ${itemStyle}
              `}
              transition={{
                duration: 2,
              }}
            >
              {letter}
            </motion.div>
          );
        })}
      </div>
      {rightTitle && <div style={{ marginLeft: "10px" }}>{rightTitle}</div>}
    </div>
  );
};

export default Odom;
