import { css } from "@emotion/react";
import React, { useState } from "react";
import { palette } from "../../Styles/theme";
import Button from "../Atoms/Button";

interface Iprops {
  inputNum: React.Dispatch<React.SetStateAction<string>>;
  [key: string]: any;
}

const defaultStyle = `
  width: 100%;
  display: flex;
  justify-content: space-between;
  height: 100%;
  box-sizing: border-box;
`;

const PerCentButtons: React.FC<Iprops> = ({ inputNum, ...props }) => {
  const [state, setState] = useState<number>(0);
  const onClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    inputNum(e.currentTarget.id);
    setState(+e.currentTarget.id);
  };

  return (
    <div
      css={css`
        ${defaultStyle}
      `}
      {...props}
    >
      {[25, 50, 75, 100].map((data, index) => (
        <Button
          key={index}
          onClick={onClick}
          id={data + ""}
          title={data === 1000 ? "MAX" : data + "%"}
          style={{
            width: "30px",
            textAlign: "center",
            margin: 0,
            cursor: "pointer",
            backgroundColor: `${state === data ? palette.blue200 : palette.blue400}`,
            padding: "10px 30px",
          }}
        />
      ))}
    </div>
  );
};

export default PerCentButtons;
