import React from "react";
import Button from "../Atoms/Button";

interface Iprops {
  onClick: () => void;
}

const LaunchBtn: React.FC<Iprops> = ({ onClick }) => {
  return (
    <Button
      onClick={onClick}
      title="Launch App"
      style={{ backgroundColor: "#B4B4B4", textAlign: "center", width: "100%", fontWeight: "bold", fontSize: "1rem", padding: "10px 10px" }}
    />
  );
};

export default LaunchBtn;
