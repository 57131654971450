import React from "react";
import { css } from "@emotion/react";
import { hoverStyle } from "../../Styles/theme";
interface Iprops {
  size?: string;
  weight?: string;
  title?: string;
  children?: React.ReactNode;
  addStyle?: string;
  [key: string]: any;
}

const defaultStyle = `
  all: unset;
  cursor: pointer;
  border-radius: 20px;
  color:white;
  font-weight: 700;
  font-size: 16px;
  opacity: 1;
  ${hoverStyle}
`;

const Button: React.FC<Iprops> = ({ title, children, addStyle, ...props }) => {
  return (
    <button
      css={css`
        ${defaultStyle}
        ${addStyle}
      `}
      {...props}
    >
      {title}
      {children}
    </button>
  );
};

export default Button;
