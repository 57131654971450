import { css } from "@emotion/react";
import React, { useEffect, useState } from "react";
import ConnectWallet from "../Molecules/ConnectWallet";
import ComContext, { IComProps } from "../../Context/Com";
import Loading from "../Atoms/Loading";
import { hoverStyle, palette } from "../../Styles/theme";
interface btnIprops {
  title: string;
  btnStyle: string;
}

interface Iprops {
  connectOnClick: () => void;
  featureOnClick: (e: React.ChangeEvent<HTMLButtonElement>) => void;
  loading: boolean;
  title: string;
  frameStyle?: string;
  [key: string]: any;
}

const defaultStyle = `
    width: 100%;
    display: flex;
    justify-content:center;
    box-sizing:border-box;
    text-align:center;
    padding: 30px;
`;

const btnStyle = `
    margin: 0 10px;
    height: 25px;
    background-color: ${palette.gray};
`;
const featureBtnStyle = `
    margin: 0 10px;
    height: 25px;
    background-color: ${palette.blue200};

    ${hoverStyle}
`;

const FeatureBtn: React.FC<Iprops> = ({ loading, title, frameStyle, featureOnClick, connectOnClick, ...props }) => {
  const comContext = React.useContext<IComProps | null>(ComContext);
  const [btncss, setBtnCss] = useState<btnIprops>({
    title: "",
    btnStyle: "",
  });

  const featureBtnAddStyle = `${featureBtnStyle} cursor: ${!loading ? "pointer" : "wait"}`;

  useEffect(() => {
    if (comContext?.state.account === "") {
      setBtnCss({ title: "Connect Wallet", btnStyle: btnStyle });
    } else {
      setBtnCss({ title, btnStyle: featureBtnAddStyle });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [comContext?.state.account, title]);

  return (
    <div
      css={css`
        ${defaultStyle}
        ${frameStyle}
      `}
    >
      <ConnectWallet
        onClick={comContext?.state.account !== "" ? featureOnClick : connectOnClick}
        title={loading ? "" : btncss.title}
        addStyle={btncss.btnStyle}
        {...props}
      >
        {loading && <Loading />}
      </ConnectWallet>
    </div>
  );
};

export default FeatureBtn;
