import React, { useEffect, useState } from "react";
import { ReactComponent as Copy } from "../../Assets/copy.svg";
import { ReactComponent as EtherScan } from "../../Assets/etherScan.svg";
import { ReactComponent as Off } from "../../Assets/off.svg";
import ComContext, { IComProps } from "../../Context/Com";
import { modalProps } from "../../Pages/Header";

interface Iprops {
  logOutOnClick: () => void;
  setModal: React.Dispatch<React.SetStateAction<modalProps>>;
}

const ConnectButtons: React.FC<Iprops> = ({ logOutOnClick, setModal }) => {
  const comContext = React.useContext<IComProps | null>(ComContext);

  const [url, setUrl] = useState<string>();

  const copyOnClick = async () => {
    navigator.clipboard.writeText(comContext?.state.account + "");
    setModal((prev: modalProps) => ({ ...prev, infoConnect: false }));
  };

  const etherOnClick = async () => {
    setUrl(
      window.ethereum.chainId === "0x66eed"
        ? `https://goerli.arbiscan.io/address/${comContext?.state.account}`
        : `https://goerli.etherscan.io/address/${comContext?.state.account}`
    );

    setTimeout(() => {
      setModal((prev: modalProps) => ({ ...prev, infoConnect: false }));
    }, 100);
  };

  useEffect(() => {
    if (window.ethereum) {
      //changenetwork
      window.ethereum.on("networkChanged", (e: string) => {
        if (e === "421613") {
          setUrl(`https://goerli.arbiscan.io/address/${comContext?.state.account}`);
        } else {
          setUrl(`https://goerli.etherscan.io/address/${comContext?.state.account}`);
        }
      });
    }
  }, []);

  return (
    <>
      <Copy onClick={copyOnClick} style={{ cursor: "pointer", padding: "0 10px", width: "24px", height: "24px" }} />
      <a href={url} target="blank" style={{ all: "unset" }}>
        <EtherScan onClick={etherOnClick} style={{ cursor: "pointer", padding: "0 10px", width: "24px", height: "24px" }} />
      </a>
      <Off onClick={logOutOnClick} style={{ cursor: "pointer", padding: "0 10px", width: "24px", height: "24px" }} />
    </>
  );
};

export default ConnectButtons;
