import { css } from "@emotion/react";
import React from "react";

const defaultStyle = `
    background: linear-gradient(0deg, rgba(33, 33, 33, 0.3) 0%, rgba(31, 38, 67, 0.3) 38.81%, rgba(25, 52, 152, 0.3) 100%);
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 40px;
    padding: 100px 70px 40px 70px;
    flex-direction: column;
    box-sizing: border-box;
    position: relative;
`;

interface Iprops {
  children: React.ReactNode;
  addStyle?: string;
  [key: string]: any;
}

const Content: React.FC<Iprops> = ({ children, addStyle, ...props }) => {
  return (
    <div
      css={css`
        ${defaultStyle}
        ${addStyle}
      `}
      {...props}
    >
      {children}
    </div>
  );
};

export default Content;
