import { css } from "@emotion/react";
import React from "react";
import { fontBoldStyle, palette } from "../../Styles/theme";

interface Iprops {
  fontSize?: string;

  [key: string]: any;
}
export const inputDefaultStyle = `
  ::-webkit-inner-spin-button{
    -webkit-appearance: none; 
  }
  ::-webkit-outer-spin-button{
    -webkit-appearance: none; 
  }    
  box-sizing: border-box;
  margin: 0;
  border-radius: 15px;
  height:64px;
  width: 100%;
  border: 2px solid ${palette.blue200};

  &:hover{
    border: 2px solid ${palette.mint};
  }  

  background: ${palette.blue400};
  color: white;
  outline: none;    
  
  padding-left: 20px;
`;

const Input: React.FC<Iprops> = ({ fontSize, ...props }) => {
  return (
    <input
      css={css`
        ${inputDefaultStyle}
        ${fontBoldStyle}
        ${fontSize}
      `}
      {...props}
    />
  );
};

export default Input;
