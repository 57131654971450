import OtcContract, { otcInitial } from "../Models/OtcContrax";
import ComContext, { IComProps } from "../Context/Com";
import React, { useEffect, useState } from "react";

const otcContract = new OtcContract();

export enum OtcCreateType {
  PREPARE = 0,
  ALREADY,
  COMPLEATE,
  CANCEL,
}
interface IOtcState {
  account: string;
  sendStatus: number | null;
  toAccount: string;
  fromAmount: number;
  toAmount: number;
  connect: boolean;
  fromToken: string | null;
  toToken: string | null;
  fromTokenContract: string | null;
  toTokenContract: string | null;
  toResult: boolean;
  fromResult: boolean;
  status: number;
  txHash: string;
}

function useOTC() {
  const [otcState, setOtcState] = useState<IOtcState>(otcInitial);
  const comContext = React.useContext<IComProps | null>(ComContext);
  const getOtc = async (toAccount: string) => {
    const status = await otcContract.getOtc(toAccount);

    //if (status === OtcCreateType.COMPLEATE ||status === OtcCreateType.PREPARE || status === OtcCreateType.ALREADY) {
    if (status) saveFn("Find");
    // }

    return status;
    //return await otcContract.getOtc(toAccount);
  };
  const searchToken = async (token: string) => {
    return await otcContract.search(token);
  };
  const searchAmount = async (contract: string) => {
    return await otcContract.amount(contract);
  };

  const createOtc = async (type: string, sellContract: string, sellAmount: number, buyContract: string, buyAmount: number) => {
    const res = await otcContract.createOtc(type, sellContract, buyContract, sellAmount, buyAmount);
    if (res) {
      setOtcState((prev: IOtcState) => {
        return {
          ...prev,
          fromResult: false,
          toResult: false,
          status: OtcCreateType.ALREADY,
          fromTokenContract: sellContract,
          toTokenContract: buyContract,
          fromAmount: sellAmount,
          toAmount: buyAmount,
        };
      });
    }

    return res;
  };
  const approveOtc = async () => {
    return await otcContract.approveOtc();
  };

  const cancelOtc = async () => {
    return await otcContract.cancelOtc();
  };

  const depositOtc = async () => {
    return await otcContract.depositOtc();
  };
  const allownanceOtc = async () => {
    return await otcContract.allownanceOtc();
  };

  const initialFn = async () => {
    await otcContract.connectContract();
  };

  const initialState = () => {
    setOtcState(otcInitial);
  };

  useEffect(() => {
    const timeId = setInterval(() => {
      saveFn("Find");
    }, 3000);
    return () => clearInterval(timeId);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [otcState]);

  const saveFn = async (type: string) => {
    const res = await otcContract.otcData(type);

    if (res) setOtcState((prev: IOtcState) => ({ ...prev, ...res }));
  };

  useEffect(() => {
    if (comContext?.state.account !== "") initialFn();
  });
  window.ethereum.on("networkChanged", (e: string) => {
    initialFn();
  });

  return {
    otcState,
    getOtc,
    searchToken,
    searchAmount,
    createOtc,
    cancelOtc,
    approveOtc,
    allownanceOtc,
    depositOtc,
    saveFn,
    initialState,
  };
}

export default useOTC;
