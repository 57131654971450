import { css } from "@emotion/react";
import React, { useState } from "react";
import ComContext, { IComProps } from "../Context/Com";
import ConnectMetaModal from "../Components/Molecules/ConnectMetaModal";
import useConnect from "../Hooks/useConnect";
import GenStaking from "../Components/Organisms/GenStaking";

import FeverStaking from "../Components/Organisms/FeverStaking";
import Tab from "../Components/Molecules/Tab";
import EarnGns from "../Components/Organisms/EarnGns";
import ContentFrame from "../Components/Atoms/ContentFrame";

const stMenuArr = [
  {
    title: "Gen Staking",
    id: "stgen",
  },
  {
    title: "Fever Staking",
    id: "fever",
  },
  /*
  {
    title: "Earn GNS",
    id: "gns",
  },*/
];

const Staking = () => {
  const comContext = React.useContext<IComProps | null>(ComContext);
  const [connectMetaModal, setConnectMetaModal] = useState<boolean>(false);
  const [tab, setTab] = useState<string>(stMenuArr[0].id);
  const { connect } = useConnect();

  // 27일 출시 일정으로 Fever 기능 먼저 구현... Tab 이동 홀딩
  const onClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    setTab(e.currentTarget.id + "");
  };

  const connectOnClick = async () => setConnectMetaModal(!connectMetaModal);

  const metaOnClick = async () => {
    const account = await connect();
    if (account) {
      comContext?.actions.setCom({ account });
      setConnectMetaModal(false);
    }
  };

  return (
    <>
      {connectMetaModal && comContext?.state.account === "" && <ConnectMetaModal onClick={metaOnClick} modalOnClick={() => setConnectMetaModal(false)} />}
      <ContentFrame>
        <Tab tab={tab} menu={stMenuArr} onClick={onClick} />
        {tab === "stgen" && <GenStaking connectOnClick={connectOnClick} />}
        {tab === "fever" && <FeverStaking connectOnClick={connectOnClick} />}
        {/*tab === "gns" && <EarnGns />*/}
      </ContentFrame>
    </>
  );
};

export default Staking;
