import React from "react";
import ContentFrame from "../Components/Atoms/ContentFrame";
import LinkTag from "../Components/Atoms/LinkTag";
import { flexCenter, fontSizePalette } from "../Styles/theme";

const btnAddStyle = `
  background: linear-gradient(180deg, rgba(33, 33, 33, 0.3) 0%, rgba(31, 38, 67, 0.3) 38.81%, rgba(25, 52, 152, 0.3) 100%);
  width: 500px;
  margin: 20px 0;
  border-radius: 25px;
  padding: 10px 0;
  ${fontSizePalette.lg}
`;

const Otc = () => {
  return (
    <ContentFrame addStyle={`${flexCenter} flex-direction:column; `}>
      <LinkTag link="/otc/token" title="Token" addStyle={btnAddStyle} />
      <LinkTag link="/otc/file" title="File" addStyle={btnAddStyle} />
    </ContentFrame>
  );
};

export default Otc;
