import { css } from "@emotion/react";
import React from "react";
import { networkModalProps } from "../../Pages/Header";

interface Iprops {
  children?: React.ReactNode;
  [key: string]: any;
}

const defaultStyle = `
position: absolute;
left: 0;
right: 0;
width: 150px;
padding: 5px 0;
margin: 10px auto;
border-radius: 30px;
background-color: rgba( 255, 255, 255, 0.1 );
display: flex;
justify-content: center;
`;

const NetworkModal: React.FC<Iprops> = ({ children, ...props }) => {
  return (
    <div
      css={css`
        ${defaultStyle}
      `}
      {...props}
    >
      {children}
    </div>
  );
};

export default NetworkModal;
