import React from "react";
import { motion } from "framer-motion";
import { css } from "@emotion/react";
import { fontBoldStyle, fontSizePalette } from "../../Styles/theme";

interface menuProps {
  id: string;
  title: string;
}

interface Iprops {
  onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
  tab: string;
  menu: menuProps[];
}
const stakingMenuStyle = `
  all: unset;
  cursor: pointer;
  z-index: 100;
`;
const Tab: React.FC<Iprops> = ({ onClick, tab, menu }) => {
  return (
    <div
      css={css`
        ${fontBoldStyle}
        ${fontSizePalette.sm}
      `}
      style={{
        marginTop: "100px",
        borderRadius: "45px",
        background: "linear-gradient(0deg, rgba(33, 33, 33, 0.3) 0%, rgba(31, 38, 67, 0.3) 38.81%, rgba(25, 52, 152, 0.3) 100%)",
        padding: "10px  30px",

        width: "100%",
        display: "grid",
        gridTemplateColumns: `repeat(${menu.length}, minmax(0, 1fr))`,
        boxSizing: "border-box",
        height: "70px",
        textAlign: "center",
        position: "relative",
      }}
    >
      {menu.map((data, index) => (
        <button
          id={data.id}
          css={css`
            ${stakingMenuStyle}
            ${tab !== data.id ? "opacity: 0.3;" : ""}
          `}
          key={index}
          onClick={onClick}
        >
          {data.title}
        </button>
      ))}

      <div
        style={{
          position: "absolute",
          width: "100%",
          height: "100%",
          padding: "10px 30px",

          display: "flex",
          boxSizing: "border-box",
          justifyContent: `${
            tab === menu[0].id
              ? "flex-start"
              : menu.length === 3 && tab === menu[1].id
              ? "center"
              : menu.length === 2 && tab === menu[1].id
              ? "flex-end"
              : tab === menu[2].id
              ? "flex-end"
              : "flex-start"
          }`,
        }}
      >
        <motion.div
          style={{
            borderRadius: "30px",
            zIndex: "1",
            width: `calc(100%/${menu.length})`,
            height: "100%",
            background: "linear-gradient(180deg, #06D6B7 0%, #193498 100%)",
          }}
          transition={{
            type: "spring",
            stiffness: 700,
            damping: 25,
          }}
          layout
        />
      </div>
    </div>
  );
};

export default Tab;
