import { css } from "@emotion/react";
import React, { useEffect, useState } from "react";
import { dashboardfetcher } from "../Apis/api";
import Odom from "../Components/Atoms/Odom";
import { fontBoldStyle, fontSizePalette, fontSmThinStyle } from "../Styles/theme";
import { numDigit } from "../Utils/util";

const defaultStyle = `
    width: 100%;
    height: 100%;
    padding: 0 50px;
`;

const boxStyle = `
  width: 800px;
  display: flex;
  justify-content: space-between;
  align-items:center;
`;

const colStyle = `
  
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items:center;
`;

const Main = () => {
  const [tvl, setTvl] = useState<number>(0);
  const [widthResize, setWidthResize] = useState(window.innerWidth);

  const readFn = async () => {
    const { data } = await dashboardfetcher(`https://data-info-price-gen-nexterageneration.gen.foundation/staking/stgen`);

    if (tvl !== data.total_value_locked.toFixed(8)) setTvl(data.total_value_locked.toFixed(8));
  };

  useEffect(() => {
    setTimeout(() => {
      readFn();
    }, 300);

    const timeId = setInterval(() => {
      readFn();
    }, 1000 * 60);
    return () => clearInterval(timeId);
  }, []);

  useEffect(() => {
    window.addEventListener("resize", () => {
      setWidthResize(window.innerWidth);
    });
    return () => window.removeEventListener("resize", () => {});
  }, []);

  return (
    <div
      css={css`
        ${defaultStyle}
      `}
    >
      <div
        css={css`
          ${fontSmThinStyle}
          ${widthResize > 768 ? fontSizePalette.xxl : fontSizePalette.lg}
        `}
        style={{ marginTop: "200px" }}
      >
        Staking, Burn, Escrow and Bridge service on the loading
      </div>
      <div
        css={css`
          ${fontSmThinStyle}

          ${widthResize > 768 ? fontSizePalette.xxl : fontSizePalette.lg}
        `}
        style={{ marginTop: "10px" }}
      >
        blockchain platform.
      </div>

      <div
        css={css`
          ${widthResize > 768 ? boxStyle : colStyle}
        `}
        style={{ marginTop: "30px" }}
      >
        <span
          css={css`
            ${fontBoldStyle}
            ${fontSizePalette.lg}
          `}
          style={{ width: "100%" }}
        >
          Total Value Locked
        </span>
        {widthResize > 768 ? (
          <div style={{ width: "100%" }}>
            <Odom leftTitle="$" num={numDigit(tvl + "")} />
          </div>
        ) : (
          <div style={{ width: "100%", marginTop: "10px" }}>
            <Odom leftTitle="$" num={numDigit(tvl + "")} />
          </div>
        )}
      </div>
    </div>
  );
};

export default Main;
