import { css, Interpolation, Theme } from "@emotion/react";
import React from "react";
import { palette } from "../../Styles/theme";
import Button from "../Atoms/Button";
interface Iprops {
  onClick: (e: React.ChangeEvent<HTMLButtonElement>) => void;
  title: string;
  children?: React.ReactNode;
  addStyle?: string;
  [key: string]: any;
}

const defaultStyle = `
  text-align: center;
  width: 100%;
  font-weight: bold;
  font-size: 1rem;
  padding: 15px;
  border-radius: 25px;
  background-color: ${palette.gray};
`;

const ConnectWallet: React.FC<Iprops> = ({ onClick, title, children, addStyle, ...props }) => {
  return (
    <Button
      onClick={onClick}
      title={title}
      css={css`
        ${defaultStyle}
        ${addStyle}
      `}
      {...props}
    >
      {children}
    </Button>
  );
};

export default ConnectWallet;
