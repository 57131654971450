import { css } from "@emotion/react";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import Content from "../Components/Atoms/Content";
import ContentFrame from "../Components/Atoms/ContentFrame";
import Detail from "../Components/Atoms/Detail";
import Loading from "../Components/Atoms/Loading";
import WarningMsg from "../Components/Atoms/WarningMsg";
import ConnectMetaModal from "../Components/Molecules/ConnectMetaModal";
import FeatureBtn from "../Components/Organisms/FeatureBtn";
import ComContext, { IComProps } from "../Context/Com";
import useConnect from "../Hooks/useConnect";
import useFaucet from "../Hooks/useFaucet";
import useNetwork from "../Hooks/useNetwork";
import { fontBoldStyle, fontSizePalette, palette } from "../Styles/theme";
import { ReactComponent as EtherScan } from "../Assets/etherScan.svg";
import { shortAddress } from "../Utils/util";
import Button from "../Components/Atoms/Button";
import ConnectWallet from "../Components/Molecules/ConnectWallet";
const defaultSyle = `
  padding: 10px 0;
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

const Faucet = () => {
  const comContext = React.useContext<IComProps | null>(ComContext);
  const [connectMetaModal, setConnectMetaModal] = useState<boolean>(false);
  const [process, setProcess] = useState({
    loading: false,
    title: "Send Me 10 GEN",
    errorTitle: "",
    txHash: "",
  });

  const { connect } = useConnect();
  const { readtData, faucetToken } = useFaucet();
  const { change } = useNetwork();

  const metaOnClick = async () => {
    const account = await connect();
    if (account) {
      comContext?.actions.setCom({ account });
      setConnectMetaModal(false);
    }
  };

  useEffect(() => {
    if (window.ethereum) {
      if (window.ethereum.chainId !== "0x66eed") {
        setProcess((prev: any) => ({ ...prev, errorTitle: "", loading: false, title: "Arbitrum Network Change" }));
      }

      window.ethereum.on("networkChanged", (e: string) => {
        if (e === "421613") {
          setProcess((prev: any) => ({ ...prev, errorTitle: "", loading: false, title: "Send Me 10 GEN" }));
        } else {
          setProcess((prev: any) => ({ ...prev, errorTitle: "", loading: false, title: "Arbitrum Network Change" }));
        }
      });
    }
  }, []);

  const faucetOnclick = async () => {
    if (comContext?.state.account !== "" && window.ethereum) {
      if (window.ethereum.chainId !== "0x66eed") {
        setProcess((prev: any) => ({ ...prev, errorTitle: "", loading: false, title: "Arbitrum Network Change" }));
        change("Arbitrum");
      } else {
        const data = await readtData();
        if (data) {
          setProcess((prev: any) => ({ ...prev, errorTitle: "", loading: true, title: "" }));
          const res = await faucetToken();

          if (res) {
            toast.success("Transaction successful!");
            setProcess((prev: any) => ({ ...prev, txHash: res, errorTitle: "", loading: false, title: "Send Me 10 GEN" }));
          } else {
            toast.error(`Transaction failed`);
            setProcess((prev: any) => ({ ...prev, errorTitle: "", loading: false, title: "Send Me 10 GEN" }));
          }
        } else {
          setProcess((prev: any) => ({ ...prev, errorTitle: "You can receive GEN tokens after 12 hours." }));
        }
      }
    }
  };

  const connectOnClick = async () => {
    setConnectMetaModal(!connectMetaModal);
  };
  return (
    <>
      {connectMetaModal && comContext?.state.account === "" && <ConnectMetaModal onClick={metaOnClick} modalOnClick={() => setConnectMetaModal(false)} />}

      <ContentFrame>
        <div style={{ paddingTop: "100px" }} />
        <Content addStyle="padding-top: 20px;">
          <div
            css={css`
              ${fontSizePalette.base}
            `}
            style={{ width: "100%", textAlign: "center", margin: "10px 0" }}
          >
            GEN Goerli FAUCET
          </div>
          <Detail title="Goerli GEN" content="12 Hours" />

          <div
            css={css`
              ${defaultSyle}
            `}
          >
            <span
              css={css`
                ${fontBoldStyle}
              `}
            >
              Tx Hash
            </span>
            {process.txHash !== "" && (
              <a
                style={{ textDecoration: "none", color: "green", display: "flex", alignItems: "center" }}
                href={`https://goerli.arbiscan.io/tx/${process.txHash}`}
                target="_blank"
                rel="noreferrer noopener"
              >
                <EtherScan style={{ color: "white", padding: "0 5px", width: "24px", height: "24px" }} />
                {shortAddress(process.txHash)}
              </a>
            )}
          </div>

          <FeatureBtn
            disabled={process.loading}
            loading={process.loading}
            title={process.title}
            connectOnClick={connectOnClick}
            featureOnClick={faucetOnclick}
          />
          <WarningMsg state={process.errorTitle !== "" ? true : false} title={process.errorTitle} />
        </Content>
        <Content addStyle="padding-top: 20px; margin-top: 20px;">
          <div
            css={css`
              ${fontSizePalette.base}
            `}
            style={{ width: "100%", textAlign: "center", margin: "20px 0" }}
          >
            Arbitrum Goerli ETH Faucet
          </div>
          <a
            href="https://faucet.quicknode.com/arbitrum/goerli"
            target="blank"
            style={{ textDecoration: "none", width: "100%", display: "flex", justifyContent: "center", boxSizing: "border-box", padding: "30px" }}
          >
            <ConnectWallet onClick={() => {}} title="ETH Faucet" style={{ backgroundColor: palette.blue200 }} />
          </a>
        </Content>
      </ContentFrame>
    </>
  );
};

export default Faucet;
