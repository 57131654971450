import { ethers } from "ethers";
import { ERC20_ABI } from "../Types/abi/ERC20";
import { CONVERT_GEN_ABI } from "../Types/abi/StakingAbi";

export interface IBurnState {
  myGen: number;
  myStGen: number;
  inputValue: number;
}

export const burnInitial = {
  myGen: 0,
  myStGen: 0,
  inputValue: 0, //? 보류...
};

class BurnContract {
  provider: ethers.providers.Web3Provider | null;
  brunState: IBurnState;
  account: string | null;
  stGen: ethers.Contract | null;
  gen: ethers.Contract | null;
  convertContract: ethers.Contract | null;
  changeNetwork: string | null;

  constructor() {
    this.provider = null;
    this.account = null;
    this.gen = null;
    this.stGen = null;
    this.convertContract = null;
    this.brunState = burnInitial;
    this.changeNetwork = null;
  }
  async connectContract() {
    this.provider = new ethers.providers.Web3Provider(window.ethereum);
    this.account = (await this.provider.send("eth_requestAccounts", []))[0];

    this.stGen = new ethers.Contract(
      window.ethereum.chainId === "0x66eed" ? process.env.REACT_APP_ARBITRUM_STGEN_TOKEN_CONTRACT + "" : process.env.REACT_APP_STGEN_TOKEN_CONTRACT + "",
      ERC20_ABI,
      this.provider.getSigner()
    );
    this.gen = new ethers.Contract(
      window.ethereum.chainId === "0x66eed" ? process.env.REACT_APP_ARBITRUM_GEN_CONTRACT + "" : process.env.REACT_APP_GEN_TOKEN_CONTRACT + "",
      ERC20_ABI,
      this.provider.getSigner()
    );
    this.convertContract = new ethers.Contract(
      window.ethereum.chainId === "0x66eed" ? process.env.REACT_APP_ARBITRUM_CONVERT_GEN_CONTRACT + "" : process.env.REACT_APP_CONVERT_GEN_CONTRACT + "",
      CONVERT_GEN_ABI,
      this.provider.getSigner()
    );

    if (this.stGen && this.gen) return true;
    return false;
  }
  async readBurn() {
    /*this.stGen = new ethers.Contract(
      this.changeNetwork === "Arbitrum" ? process.env.REACT_APP_ARBITRUM_STGEN_TOKEN_CONTRACT + "" : process.env.REACT_APP_STGEN_TOKEN_CONTRACT + "",
      ERC20_ABI,
      this.provider?.getSigner()
    );
    this.gen = new ethers.Contract(
      this.changeNetwork === "Arbitrum" ? process.env.REACT_APP_ARBITRUM_GEN_CONTRACT + "" : process.env.REACT_APP_GEN_TOKEN_CONTRACT + "",
      ERC20_ABI,
      this.provider?.getSigner()
    );
    this.convertContract = new ethers.Contract(
      this.changeNetwork === "Arbitrum" ? process.env.REACT_APP_ARBITRUM_CONVERT_GEN_CONTRACT + "" : process.env.REACT_APP_CONVERT_GEN_CONTRACT + "",
      CONVERT_GEN_ABI,
      this.provider?.getSigner()
    );*/

    this.brunState.myGen = Number(ethers.utils.formatEther(await this.gen?.balanceOf(this.account)));
    this.brunState.myStGen = Number(ethers.utils.formatEther(await this.stGen?.balanceOf(this.account)));

    return this.brunState;
  }
  async approve(amount: number) {
    this.brunState.inputValue = amount;

    try {
      const convertedNum = ethers.utils.parseUnits(this.brunState.inputValue + "", 18);
      const increaseApproveTx = await this.gen?.increaseAllowance(
        window.ethereum.chainId === "0x66eed" ? process.env.REACT_APP_ARBITRUM_CONVERT_GEN_CONTRACT + "" : process.env.REACT_APP_CONVERT_GEN_CONTRACT + "",
        convertedNum
      );
      const transferReceipt = await increaseApproveTx.wait();

      if (transferReceipt) return true;
    } catch (error) {
      return false;
    }
  }
  async convert() {
    try {
      const convertedNum = ethers.utils.parseUnits(this.brunState.inputValue + "", 18);
      const convertTx = await this.convertContract?.convertGen(convertedNum);
      const transferReceipt = await convertTx.wait();
      if (transferReceipt) return true;
    } catch (error) {
      return false;
    }
  }
}

export default BurnContract;
