import { css } from "@emotion/react";
import React from "react";
import { Link } from "react-router-dom";
import { hoverStyle, palette } from "../../Styles/theme";

const defaultStyle = `
  width: 100%;
  text-decoration: none;
  text-align: center;
  position: relative; 
  display: block;
  color:white;
 
  &:hover{
    color: ${palette.mint}
  }
`;

interface Iprops {
  link: string;
  title?: string;
  onClick?: (title: string) => void;

  children?: React.ReactNode;
  addStyle?: string;
  [key: string]: any;
}

const LinkTag: React.FC<Iprops> = ({ onClick, link, addStyle, title, click, children, ...props }) => {
  return (
    <Link
      css={css`
        ${defaultStyle}
        ${addStyle}
      `}
      to={link}
      onClick={() => (onClick && title ? onClick(title) : "")}
      {...props}
    >
      {title}
      {children}
    </Link>
  );
};

export default LinkTag;
