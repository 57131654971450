import { css } from "@emotion/react";
import React, { useEffect, useState } from "react";
import { flexCenter, palette } from "../../Styles/theme";
import HeaderItem from "../Molecules/HeaderItem";
import { ReactComponent as Close } from "../../Assets/close.svg";

const testStyle = `
position: fixed;
left: 0;
right: 0;
margin: 0 auto;
width: 300px;
`;

const flexStyle = `
  display: flex;
  justify-content: space-between;
  width: 300px;
`;

const colflexStyle = `
  display: flex;
  flex-direction: column;
  width: 100px;
  height: 100px;
`;

const fixedStyle = `
  position: fixed;
  top: 0;
  right: 0; 
  width: 300px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 1;

  background-color: ${palette.blue400};
`;

const displayNone = `
    display: none;
`;

interface Iprops {
  setModal: React.Dispatch<React.SetStateAction<boolean>>;
}

const HeaderItems: React.FC<Iprops> = ({ setModal }) => {
  const [widthResize, setWidthResize] = useState(window.innerWidth);
  const [clickLink, setClickLink] = useState<string>("");

  useEffect(() => {
    window.addEventListener("resize", () => {
      setWidthResize(window.innerWidth);
    });
    return () => window.removeEventListener("resize", () => {});
  }, []);

  const linkArr = [
    {
      link: "/staking",
      title: "Staking",
    },
    {
      link: "/burn",
      title: "Burn",
    },
    {
      link: "/otc/token",
      title: "OTC",
    },
    {
      link: "/faucet",
      title: "Faucet",
    },
  ];
  const linkOnClick = (title: string) => {
    setClickLink(title);
    setModal(false);
  };
  // window.location.href
  return (
    <div
      css={css`
        ${widthResize > 1024 ? flexStyle : fixedStyle}
      `}
    >
      {widthResize < 1024 && setModal && (
        <Close onClick={() => setModal(false)} style={{ color: "white", position: "absolute", right: 10, top: 10, width: "34px", cursor: "pointer" }} />
      )}

      {linkArr.map((data, index) => (
        <div
          key={index}
          css={css`
            ${widthResize > 1024 ? flexCenter : colflexStyle}
          `}
        >
          <HeaderItem link={data.link} title={data.title} onClick={linkOnClick} click={clickLink} />
        </div>
      ))}
    </div>
  );
};

export default HeaderItems;
// <div style={{ display: "flex", width: "100%", justifyContent: "space-between" }}>
