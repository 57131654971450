export const networks = {
  arbitrum: {
    chainId: `0x66eed`,
    chainName: "Arbitrum Goerli",
    nativeCurrency: {
      name: "AGOR",
      symbol: "AGOR",
      decimals: 18,
    },
    rpcUrls: ["https://arbitrum-goerli.public.blastapi.io"],
    blockExplorerUrls: ["https://goerli-rollup-explorer.arbitrum.io"],
  },
  goerli: {
    chainId: `0x5`,
    chainName: "Ethereum Goerli",
    nativeCurrency: {
      name: "ETH",
      symbol: "ETH",
      decimals: 18,
    },
    rpcUrls: ["https://eth-goerli.public.blastapi.io"],
    blockExplorerUrls: ["https://goerli.etherscan.io"],
  },
};
