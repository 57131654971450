import React, { useEffect, useState } from "react";
import ContentFrame from "../Components/Atoms/ContentFrame";
import ConnectMetaModal from "../Components/Molecules/ConnectMetaModal";
import ComContext, { IComProps } from "../Context/Com";
import Tab from "../Components/Molecules/Tab";
import useConnect from "../Hooks/useConnect";
import OtcFind from "./OtcFind";
import OtcCreate from "./OtcCreate";
import OtcDeposit from "./OtcDeposit";
import OtcComplete from "./OtcComplete";

export enum PageType {
  FIND_PAGE = 1,
  CREATE_PAGE,
  DEPOSIT_PAGE,
  COMPLETE_PAGE,
}

const otcMenuArr = [
  {
    title: "Trade Started",
    id: "start",
  },
  {
    title: "Pending",
    id: "pending",
  },
  {
    title: "Complete",
    id: "complete",
  },
];

const OtcToken = () => {
  const comContext = React.useContext<IComProps | null>(ComContext);
  const [connectMetaModal, setConnectMetaModal] = useState<boolean>(false);

  const { connect } = useConnect();

  const [tab, setTab] = useState<string>(otcMenuArr[0].id);
  const [depositType, setDepositType] = useState<string>("");
  const [otcPage, setOtcPage] = useState<number>(PageType.FIND_PAGE);

  const metaOnClick = async () => {
    const account = await connect();
    if (account) {
      comContext?.actions.setCom({ account });
      setConnectMetaModal(false);
    }
  };
  const connectOnClick = async () => {
    setConnectMetaModal(!connectMetaModal);
  };

  return (
    <>
      {connectMetaModal && comContext?.state.account === "" && <ConnectMetaModal onClick={metaOnClick} modalOnClick={() => setConnectMetaModal(false)} />}
      <ContentFrame>
        {/* <Tab tab={tab} onClick={onClick} menu={otcMenuArr} /> */}
        <Tab tab={tab} menu={otcMenuArr} />

        {otcPage === PageType.FIND_PAGE && <OtcFind setType={setDepositType} setTab={setTab} setOtcPage={setOtcPage} connectOnClick={connectOnClick} />}
        {otcPage === PageType.DEPOSIT_PAGE && <OtcDeposit type={depositType} setTab={setTab} setOtcPage={setOtcPage} connectOnClick={connectOnClick} />}
        {otcPage === PageType.CREATE_PAGE && <OtcCreate setType={setDepositType} setTab={setTab} setOtcPage={setOtcPage} connectOnClick={connectOnClick} />}
        {otcPage === PageType.COMPLETE_PAGE && <OtcComplete setTab={setTab} setOtcPage={setOtcPage} />}
      </ContentFrame>
    </>
  );
};

export default OtcToken;
