import { css, keyframes } from "@emotion/react";
import React from "react";

const circleKeyframes = keyframes`
    0%{
      transform: translate(0%, 0%) rotate(0deg);
    }
    100%{
      transform: translate(0%, 0%) rotate(360deg);
    }
`;

const defaultSyle = `
    margin: auto;
    position:absolute;
    
    width: 25px;
    height: 25px;
    border-radius: 50%;
    border: 5px solid rgba(255,255,255,.1);
    border-top: 5px solid #fff;
`;

const Loading = () => {
  return (
    <div
      style={{
        display: "flex",
        width: "100%",
        height: "100%",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <div
        css={css`
          ${defaultSyle}
          animation: ${circleKeyframes} 1.5s infinite linear;
        `}
      />
    </div>
  );
};

export default Loading;
