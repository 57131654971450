import React from "react";
import Button from "../Atoms/Button";
import { ReactComponent as Close } from "../../Assets/close.svg";
import { ReactComponent as MetaMask } from "../../Assets/metaMask.svg";
import Modal from "../Atoms/Modal";
import { flexCenter, fontBoldStyle, fontSizePalette, palette } from "../../Styles/theme";
import { css } from "@emotion/react";

const defaultStyle = `
  position: relative;
  width: 540px;
  height: 300px;
  ${flexCenter}
  border-radius: 20px;
  background: linear-gradient(180deg, rgba(33, 33, 33, 0.3) 0%, rgba(31, 38, 67, 0.3) 38.81%, rgba(25, 52, 152, 0.3) 100%);
  box-shadow: 6px 17px 33px 5px rgba(0, 0, 0, 0.25);
  color: white;
`;

interface Iprops {
  modalOnClick: () => void;
  onClick: () => void;
}

const ConnectMetaModal: React.FC<Iprops> = ({ modalOnClick, onClick }) => {
  return (
    <Modal style={{ backgroundColor: `${palette.blue300}` }}>
      <div
        css={css`
          ${defaultStyle}
        `}
      >
        <div
          css={css`
            ${fontBoldStyle}
            ${fontSizePalette.lg}
          `}
          style={{ position: "absolute", top: 15, left: 15 }}
        >
          Connect Wallet
        </div>

        <div
          css={css`
            ${flexCenter}
          `}
          style={{ width: "100%", textAlign: "center" }}
        >
          <Button onClick={onClick}>
            <MetaMask
              style={{
                borderRadius: "20px",
                border: "2px solid #06D6B7",
                background: "linear-gradient(180deg, rgba(33, 33, 33, 0.3) 0%, rgba(31, 38, 67, 0.3) 38.81%, rgba(25, 52, 152, 0.3) 100%)",
                boxShadow: "6px 17px 33px 5px rgba(0, 0, 0, 0.25)",
                width: "250px",
                height: "80px",
                padding: "0 20px",
              }}
            />
          </Button>
        </div>
        <Close onClick={modalOnClick} style={{ color: "white", position: "absolute", right: 10, top: 10, width: "34px", cursor: "pointer" }} />
      </div>
    </Modal>
  );
};

export default ConnectMetaModal;
