import React, { useEffect, useState } from "react";
import useOTC from "../../Hooks/useOtc";
import { fontSizePalette, palette } from "../../Styles/theme";
import Button from "../Atoms/Button";
import Input from "../Atoms/Input";
import Modal from "../Atoms/Modal";
import { ReactComponent as Close } from "../../Assets/close.svg";
import { css } from "@emotion/react";
import { initialState, Istate } from "../../Pages/OtcCreate";
import WarningMsg from "../Atoms/WarningMsg";

interface Iprops {
  closeOnClick: () => void;
  searchOnClick: (sellTitle: string, sellContract: string, sellTotalAmount: number) => void;
}

const SearchContractModal: React.FC<Iprops> = ({ closeOnClick, searchOnClick }) => {
  const { searchToken, searchAmount } = useOTC();
  const [searchTokenRes, setSearchTokenRes] = useState<string>("");

  const [state, setState] = useState<Istate>(initialState);

  const searchOnChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const res = await searchToken(e.target.value);
    if (res !== "error") {
      const data = Number(await searchAmount(e.target.value));

      //setState((prev: Istate) => ({ ...prev, sellTitle: res, sellContract: e.target.value, sellAmount: data }));
      setState((prev: Istate) => ({ ...prev, sellTitle: res, sellContract: e.target.value, sellTotalAmount: data }));
    }
    setSearchTokenRes(res);
  };
  return (
    <Modal>
      <div
        style={{
          width: "400px",
          height: "400px",
          borderRadius: "30px",
          backgroundColor: palette.blue300,
          position: "relative",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          boxSizing: "border-box",
        }}
      >
        <div
          css={css`
            ${fontSizePalette.lg}
          `}
          style={{ width: "100%", paddingLeft: "30px" }}
        >
          Token Select
        </div>
        <div
          style={{
            width: "100%",
            boxSizing: "border-box",
            padding: "20px 20px",
            position: "relative",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Input onChange={searchOnChange} fontSize={fontSizePalette.base} placeholder="Contract Address" style={{ margin: "10px 0" }} />

          <WarningMsg state={searchTokenRes === "error"} title="Please enter the correct contract address" />
          {searchTokenRes !== "" && searchTokenRes !== undefined && searchTokenRes !== "error" && (
            <Button
              style={{ border: `2px solid ${palette.blue200}`, padding: "20px 0", textAlign: "center" }}
              onClick={() => searchOnClick(state.sellTitle, state.sellContract, state.sellTotalAmount)}
            >
              {searchTokenRes}
            </Button>
          )}
        </div>

        <Close onClick={closeOnClick} style={{ color: "white", position: "absolute", right: 10, top: 10, width: "34px", cursor: "pointer" }} />
      </div>
    </Modal>
  );
};

export default SearchContractModal;
