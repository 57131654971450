import { ethers } from "ethers";
import { networks } from "../Network/networks";
import { ERC20_ABI } from "../Types/abi/ERC20";

class CommAccount {
  provider: ethers.providers.Web3Provider | null;
  gen: ethers.Contract | null;
  stGen: ethers.Contract | null;
  account: string | null;
  genAmount: number;
  stGenAmount: number;
  constructor() {
    this.provider = null;
    this.gen = null;
    this.stGen = null;
    this.genAmount = 0;
    this.stGenAmount = 0;
    this.account = null;
  }

  async initContractAll() {}

  async connectContract() {
    // ⬇ 지갑 연결 요청
    this.provider = new ethers.providers.Web3Provider(window.ethereum);
    const signer = await this.provider.getSigner();

    this.gen = new ethers.Contract(process.env.REACT_APP_GEN_TOKEN_CONTRACT + "", ERC20_ABI, signer);
    this.stGen = new ethers.Contract(process.env.REACT_APP_STGEN_TOKEN_CONTRACT + "", ERC20_ABI, signer);

    if (signer) {
      this.account = (await this.provider.send("eth_requestAccounts", []))[0];

      // 아래 현재 에러 남...
      //this.genAmount = Number(ethers.utils.formatEther(await this.gen?.balanceOf(this.account)));
      //this.stGenAmount = Number(ethers.utils.formatEther(await this.stGen?.balanceOf(this.account)));
    }
    return this.account;
  }

  async changeNetwork(mainnet: string) {
    try {
      mainnet === "Arbitrum"
        ? window.ethereum.request({
            method: "wallet_addEthereumChain",
            params: [
              {
                ...networks["arbitrum"],
              },
            ],
          })
        : window.ethereum.request({
            method: "wallet_addEthereumChain",
            params: [
              {
                ...networks["goerli"],
              },
            ],
          });

      return true;
    } catch (e) {
      return false;
    }
  }

  genContract() {
    return this.gen;
  }
  stGenContract() {
    return this.stGen;
  }
  providerContract() {
    this.account = "";
    this.provider = null;
    this.genAmount = 0;
    this.stGenAmount = 0;
    return;
  }
  accountRead() {
    return this.account;
  }
}

export default CommAccount;
