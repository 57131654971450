import { css } from "@emotion/react";
import React from "react";
import { palette } from "../../Styles/theme";

interface Iprops {
  children: React.ReactNode;
  [key: string]: any;
}

const defaultStyle = `
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
`;

const Modal: React.FC<Iprops> = ({ children, ...props }) => {
  return (
    <div
      css={css`
        ${defaultStyle}
      `}
      {...props}
    >
      {children}
    </div>
  );
};

export default Modal;
