import React, { useEffect, useState } from "react";
import ComContext, { IComProps } from "../Context/Com";

import FaucetContract, { IFaucetState } from "../Models/FaucetContract";

export const faucetInitial = {
  state: false,
  tx: "",
};
const faucetContract = new FaucetContract();

function useFaucet() {
  const comContext = React.useContext<IComProps | null>(ComContext);
  const [faucetState, setFaucetState] = useState<IFaucetState>(faucetInitial);

  const readtData = async () => {
    const result = await faucetContract.connectContract();

    if (result) {
      const res = await faucetContract.readStatus();
      return res;
    } else {
    }
  };
  const faucetToken = async () => {
    return await faucetContract.sendToken();
  };

  const saveFn = async () => {
    const state = await readtData();
    if (state) setFaucetState((prev: IFaucetState) => ({ ...prev, ...state }));
  };

  useEffect(() => {
    if (comContext?.state.account !== "") {
      saveFn();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [comContext?.state]);

  return {
    readtData,
    faucetState,
    faucetToken,
  };
}

export default useFaucet;
