import { ethers } from "ethers";
import { ERC20_ABI } from "../Types/abi/ERC20";
import { FAUCET_ABI } from "../Types/abi/FaucetAbi";
import { CONVERT_GEN_ABI } from "../Types/abi/StakingAbi";

export interface IFaucetState {
  tx: string;
  state: boolean;
}

export const faucetInitial = {
  state: false,
  tx: "",
};

class FaucetContract {
  provider: ethers.providers.Web3Provider | null;
  faucetState: IFaucetState;
  account: string | null;
  faucetContract: ethers.Contract | null;

  constructor() {
    this.provider = null;
    this.account = null;
    this.faucetState = faucetInitial;
    this.faucetContract = null;
  }
  async connectContract() {
    this.provider = new ethers.providers.Web3Provider(window.ethereum);
    this.account = (await this.provider.send("eth_requestAccounts", []))[0];

    if (window.ethereum.chainId === "0x66eed") {
      this.faucetContract = new ethers.Contract(process.env.REACT_APP_FAUCET_CONTRACT + "", FAUCET_ABI, this.provider.getSigner());
      return true;
    }

    return false;
  }

  async readStatus() {
    return await this.faucetContract?.allowedToWithdraw(this.account);
  }

  async sendToken() {
    try {
      const tx = await this.faucetContract?.requestTokens();

      const transferReceipt = await tx.wait();
      if (transferReceipt) return transferReceipt.transactionHash;
    } catch (error) {
      return "";
    }
  }
}

export default FaucetContract;
