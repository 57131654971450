import { css } from "@emotion/react";
import React, { useEffect, useState } from "react";
import { BrowserRouter, Routes, Route, useLocation } from "react-router-dom";
import Header from "./Pages/Header";
import { ComProvider } from "./Context/Com";
import Bridge from "./Pages/Bridge";
import Burn from "./Pages/Burn";
import DApp from "./Pages/DApp";
import Staking from "./Pages/Staking";
import History from "./Pages/History";
import Intro from "./Pages/Intro";
import Main from "./Pages/Main";
import Otc from "./Pages/Otc";
import { flexCenter, fontBoldStyle } from "./Styles/theme";
import OtcToken from "./Pages/OtcToken";
import OtcFile from "./Pages/OtcFile";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Faucet from "./Pages/Faucet";
import ReactGA from "react-ga";
import { createBrowserHistory } from "history";
export const headerStyle = `
  position: relative;
  display: flex;
  flex-direction: column;
`;
const maxWidthStyle = `
    max-width: 1680px;
    width: 100%;
    height: 100vh;
    ${flexCenter}
`;

declare global {
  interface Window {
    ethereum: any;
  }
}

function App() {
  return (
    <ComProvider>
      <div
        className="App"
        css={css`
          ${fontBoldStyle}
        `}
        style={{
          width: "100%",
          height: "100%",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Header />
        <div
          css={css`
            ${maxWidthStyle}
          `}
        >
          <Routes>
            <Route path="/" element={<Intro />} />
            <Route path="/finance" element={<Main />} />

            <Route path="/staking" element={<Staking />} />
            <Route path="/burn" element={<Burn />} />
            <Route path="/otc" element={<Otc />} />
            <Route path="/otc/token" element={<OtcToken />} />
            <Route path="/otc/file" element={<OtcFile />} />

            <Route path="/bridge" element={<Bridge />} />
            <Route path="/dApp" element={<DApp />} />
            <Route path="/history" element={<History />} />
            <Route path="/faucet" element={<Faucet />} />
          </Routes>
          <ToastContainer position={"bottom-left"} closeOnClick={true} autoClose={2000} />
        </div>
      </div>
    </ComProvider>
  );
}

export default App;
