import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import Content from "../Components/Atoms/Content";
import Detail from "../Components/Atoms/Detail";
import Loading from "../Components/Atoms/Loading";
import ConnectWallet from "../Components/Molecules/ConnectWallet";
import useOTC, { OtcCreateType } from "../Hooks/useOtc";
import { hoverStyle, noneHoverStyle, palette } from "../Styles/theme";
import { shortAddress } from "../Utils/util";
import { PageType } from "./OtcToken";
import ComContext, { IComProps } from "../Context/Com";
interface Iprops {
  connectOnClick: () => void;
  setOtcPage: React.Dispatch<React.SetStateAction<number>>;
  setTab: React.Dispatch<React.SetStateAction<string>>;
  type: string;
}

const featureBtnStyle = `
margin: 0 10px;
height: 25px;
background-color: ${palette.blue200};

${hoverStyle}
`;

const btnStyle = `
margin: 0 10px;
height: 25px;
background-color: ${palette.gray};

`;

const OtcDeposit: React.FC<Iprops> = ({ type, setTab, connectOnClick, setOtcPage }) => {
  const comContext = React.useContext<IComProps | null>(ComContext);
  const { otcState, cancelOtc, approveOtc, depositOtc, saveFn, initialState } = useOTC();
  const [process, setProcess] = useState({
    approveResult: false,
    target: "",
    loading: false,
  });
  const [success, setSuccess] = useState<boolean>(false);
  const [next, setNext] = useState<boolean>(false);
  const cancelOnClick = async (e: React.ChangeEvent<HTMLButtonElement>) => {
    setProcess((prev: any) => ({ ...prev, target: e.target.id, loading: true }));
    const data = await cancelOtc();
    if (data) {
      toast.success("Transaction successful!");
      setProcess((prev: any) => ({ ...prev, loading: false }));
      setTab("pending");
      setOtcPage(PageType.FIND_PAGE);
    } else {
      toast.error("Transaction failed");
      setProcess((prev: any) => ({ ...prev, loading: false }));
    }
  };
  const approveOnClick = async (e: React.ChangeEvent<HTMLButtonElement>) => {
    setProcess((prev: any) => ({ ...prev, target: e.target.id, loading: true }));
    const data = await approveOtc();
    if (data) {
      toast.success("Transaction successful!");
      setNext(true);
      setProcess((prev: any) => ({ ...prev, target: "Deposit", approveResult: true, loading: false }));
    } else {
      toast.error("Transaction failed");
      setProcess((prev: any) => ({ ...prev, target: "Deposit", loading: false }));
    }
  };

  const depositOnClick = async (e: React.ChangeEvent<HTMLButtonElement>) => {
    const data = await depositOtc();
    if (data) {
      toast.success("Transaction successful!");
      saveFn("Find");
      setSuccess(true);
    } else {
      toast.error("Transaction failed");
      setSuccess(false);
    }
    setProcess((prev: any) => ({ ...prev, target: "Deposit", loading: false }));
  };

  useEffect(() => {
    if (comContext?.state.account !== "") {
      if (otcState.fromResult) setSuccess(true);
      if (otcState.fromResult === true && otcState.toResult === true) {
        setTab("complete");
        setOtcPage(PageType.COMPLETE_PAGE);
      }
    } else {
      initialState();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [comContext?.state.account, otcState]);

  const featureArr = [
    { title: "Cancel", btnStyle: `${featureBtnStyle}   cursor: ${!process.loading ? "pointer" : "wait"}` },
    { title: "Approve", btnStyle: `${featureBtnStyle}   cursor: ${!process.loading ? "pointer" : "wait"}` },
    {
      title: "Deposit",
      btnStyle: `${!success ? featureBtnStyle : noneHoverStyle + btnStyle}   cursor: ${!process.loading ? (success ? "default" : "pointer") : "wait"}`,
    },
  ];

  return (
    <>
      <div style={{ margin: "40px 0" }} />
      <Content addStyle="padding: 20px;">
        <Detail title="Sell Crypto" content={`${otcState.toAmount} ${otcState.toToken}`} />

        <Detail title="Buy Crypto" content={`${otcState.fromAmount} ${otcState.fromToken}`} />
        <Detail title="Other User" content={otcState.toResult === false ? "Deposit Holding" : "Deposit Success!"} rightAddStyle={`color:${palette.gray};`} />
        <Detail title="Other User Account" content={shortAddress(otcState.toAccount)} />
        <Detail
          title="My Deposit "
          content={`${!otcState.fromResult ? "Not Yet" : "Success"}`}
          rightAddStyle={!otcState.fromResult ? `color:red;` : `color:green;`}
        />
        <div style={{ display: "flex", width: "100%", justifyContent: "center", boxSizing: "border-box", textAlign: "center", padding: "20px 30px" }}>
          <ConnectWallet
            disabled={process.loading}
            onClick={cancelOnClick}
            id={featureArr[0].title}
            title={process.target === featureArr[0].title && process.loading ? "" : featureArr[0].title}
          >
            {featureArr[0].title === process.target && process.loading && <Loading />}
          </ConnectWallet>
          {!otcState.fromResult && !process.approveResult && (
            <ConnectWallet
              disabled={process.loading}
              onClick={approveOnClick}
              id={featureArr[1].title}
              title={process.target === featureArr[1].title && process.loading ? "" : featureArr[1].title}
              addStyle={featureArr[1].btnStyle}
            >
              {featureArr[1].title === process.target && process.loading && <Loading />}
            </ConnectWallet>
          )}
          {(otcState.fromResult || process.approveResult) && (
            <ConnectWallet
              disabled={success}
              onClick={depositOnClick}
              id={featureArr[2].title}
              title={process.target === featureArr[2].title && process.loading ? "" : featureArr[2].title}
              addStyle={featureArr[2].btnStyle}
            >
              {featureArr[2].title === process.target && process.loading && <Loading />}
            </ConnectWallet>
          )}
        </div>
      </Content>
    </>
  );
};

export default OtcDeposit;
