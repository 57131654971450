import React, { useEffect, useState } from "react";
import ComContext, { IComProps } from "../Context/Com";
import StakingContract, { IStState } from "../Models/StakingContract";

const stakingccount = new StakingContract();

const stInitial = {
  apr: 0,
  totalStaked: "",
  myStaked: "",
  walletBalance: 0,
  myGen: "",
  depositValue: 0,
  reward: 0,
  totalgenStaked: 0,
  totalstgenStaked: 0,
  genTotalStaked: 0,
};

function useStaking(token?: string) {
  const comContext = React.useContext<IComProps | null>(ComContext);
  const [stState, setStState] = useState<IStState>(stInitial);
  const readtData = async () => {
    if (token && token !== "" && (await stakingccount.connectContract())) {
      return await stakingccount.readGenStaking(token + "");
    }
  };
  window.ethereum.on("networkChanged", async (e: string) => {
    stakingccount.connectContract();
    const res = await readtData();
    if (res) setStState((prev: IStState) => ({ ...prev, ...res }));
  });
  const saveFn = async () => {
    const res = await readtData();
    if (res) setStState((prev: IStState) => ({ ...prev, ...res }));
  };
  const approve = async (depositValue: number) => {
    setStState((prev: IStState) => ({ ...prev, depositValue }));

    return token === "gen" ? await stakingccount.approveGen(depositValue) : await stakingccount.approveFever(depositValue);
  };
  const stake = async () => {
    const res = token === "gen" ? await stakingccount.stakeGen() : await stakingccount.stakeFever();
    if (res) {
      //성공 후 초기화
      setStState((prev: IStState) => ({ ...prev, depositValue: 0 }));
    }
    return res;
  };
  const unstake = async (amount: number) => {
    const res = await stakingccount.unstake(amount);
    if (res) {
      //성공 후 초기화
      setStState((prev: IStState) => ({ ...prev, depositValue: 0 }));
    }
    return res;
  };

  const claim = async () => {
    const res = token === "gen" ? await stakingccount.claimGen() : await stakingccount.claimFever();
    if (res) {
      //성공 후 초기화
      setStState((prev: IStState) => ({ ...prev, reward: 0 }));
    }
    return res;
  };

  const initStState = () => {
    setStState(stInitial);
  };

  useEffect(() => {
    //console.log("c21231omContext?.state.account", comContext?.state.account);
    if (comContext?.state.account) {
      const timeId = setInterval(() => {
        saveFn();
      }, 3000);
      return () => clearInterval(timeId);
    } else {
      initStState();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [comContext?.state.account]);

  return {
    initStState,
    readtData,
    stState,
    approve,
    stake,
    unstake,
    claim,
    saveFn,
  };
}

export default useStaking;
