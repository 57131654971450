import CommAccount from "../Models/ComData";

const commAccount = new CommAccount();

function useNetwork() {
  const change = async (mainnet: string) => {
    if (typeof window.ethereum !== "undefined") {
      return await commAccount.changeNetwork(mainnet);
    }
  };

  return { change };
}

export default useNetwork;
