import React, { useEffect, useState } from "react";
import ConnectWallet from "../Molecules/ConnectWallet";
import { ReactComponent as MoreBtn } from "../../Assets/moreBtn.svg";
import LaunchBtn from "../Molecules/LaunchBtn";
import ComContext, { IComProps } from "../../Context/Com";
import { shortAddress } from "../../Utils/util";
import { palette } from "../../Styles/theme";
import { modalProps } from "../../Pages/Header";

interface Iprops {
  onClick: () => void;
  state: modalProps;
}

const ConnectBtn: React.FC<Iprops> = ({ onClick, state }) => {
  const comContext = React.useContext<IComProps | null>(ComContext);
  const [widthResize, setWidthResize] = useState(window.innerWidth);

  useEffect(() => {
    window.addEventListener("resize", () => {
      setWidthResize(window.innerWidth);
    });
    return () => window.removeEventListener("resize", () => {});
  }, []);

  const btnOnClick = () => {
    window.location.href = "/staking";
  };

  return (
    <div
      style={{
        display: "flex",
        boxSizing: "border-box",
        width: widthResize < 768 ? "180px" : "230px",
        justifyContent: "center",
        alignItems: "center",
        padding: "0 10px",
      }}
    >
      {window.location.href === process.env.REACT_APP_MAIN_URL + "/" ? (
        <LaunchBtn onClick={btnOnClick} />
      ) : comContext?.state.account !== "" ? (
        <ConnectWallet
          onClick={onClick}
          title={widthResize < 768 ? "Account" : shortAddress(comContext?.state.account || "")}
          style={{ backgroundColor: palette.mint }}
        >
          <MoreBtn style={{ paddingLeft: "10px", transform: `${state.infoConnect ? "rotateX(180deg)" : "rotateX(0deg)"}` }} />
        </ConnectWallet>
      ) : (
        <ConnectWallet onClick={onClick} title="Connect Wallet" />
      )}
    </div>
  );
};

export default ConnectBtn;
