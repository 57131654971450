import React from "react";
import { motion } from "framer-motion";
import { fontBoldStyle, palette } from "../Styles/theme";
import { css } from "@emotion/react";

interface WrapperIprops {
  children: React.ReactNode;
}
const Wrapper: React.FC<WrapperIprops> = ({ children }) => {
  return <span style={{}}>{children}</span>;
};

interface Iprops {
  str: string;
}

const AnimatedCharacters: React.FC<Iprops> = ({ str }) => {
  const item = {
    hidden: {
      y: "200%",

      color: palette.blue300,
      transition: {
        type: "spring",
        bounce: 0.2,
        duration: 0.85,
        ease: [0.455, 0.03, 0.515, 0.955],
      },
    },
    visible: {
      y: 0,

      color: palette.blue300,
      transition: {
        type: "spring",
        bounce: 0.2,
        ease: [0.455, 0.03, 0.515, 0.955],
        duration: 0.85,
      },
    },
  };
  const splitWords = str.split("");
  return (
    <div
      css={css`
        ${fontBoldStyle}
      `}
      style={{ display: "flex", alignItems: "center", justifyContent: "center" }}
    >
      <Wrapper>
        {splitWords.flat().map((element, index) => {
          return (
            <span
              style={{
                overflow: "hidden",
                display: "inline-block",
              }}
              key={index}
            >
              <motion.span style={{ display: "inline-block" }} variants={item}>
                {element}
              </motion.span>
            </span>
          );
        })}
      </Wrapper>
    </div>
  );
};

export default AnimatedCharacters;
