import React, { useEffect, useState } from "react";
import { css } from "@emotion/react";
import useConnect from "../Hooks/useConnect";
import { ReactComponent as Logo } from "../../src/Assets/logo.svg";
import { ReactComponent as OnlyLogo } from "../../src/Assets/onlyLogo.svg";
import ConnectMetaModal from "../Components/Molecules/ConnectMetaModal";
import AccountModal from "../Components/Organisms/AccountModal";
import ComContext, { IComProps } from "../Context/Com";
import { flexCenter, palette } from "../Styles/theme";
import HeaderItems from "../Components/Organisms/HeaderItems";
import ConnectBtn from "../Components/Organisms/ConnectBtn";
import LinkTag from "../Components/Atoms/LinkTag";
import Button from "../Components/Atoms/Button";
import NetworkBtn from "../Components/Organisms/NetworkBtn";
import NetworkModal from "../Components/Organisms/NetworkModal";
import useNetwork from "../Hooks/useNetwork";

const defaultHeaderStyle = `
    width: 100%;
    height: 100%;
    ${flexCenter}
`;
const maxWidthStyle = `
    width: 100%;
    height: 80px;
    max-width: 1680px;
    ${flexCenter}
    justify-content:space-between;
    
    padding: 0 20px;
    background-color: ${palette.blue300}
`;
const btnStyle = `
  text-align: center;
  width: 100px;
  font-weight: bold;
  font-size: 1rem;
  padding: 15px;
  border-radius: 25px;
  background-color: ${palette.blue200};
`;
export interface modalProps {
  mataConnect: boolean;
  infoConnect: boolean;
}
export interface networkModalProps {
  modal: boolean;
  title: string;
}

const Header = () => {
  const comContext = React.useContext<IComProps | null>(ComContext);
  const { connect, dis } = useConnect();
  const { change } = useNetwork();
  const [navModal, setNavModal] = useState<boolean>(false);
  const [modal, setModal] = useState<modalProps>({
    mataConnect: false,
    infoConnect: false,
  });
  const [networkSel, setNetworkSel] = useState<networkModalProps>({
    modal: false,
    title: "",
  });
  const [widthResize, setWidthResize] = useState(window.innerWidth);

  const connectOnClick = () => {
    if (comContext?.state.account === "") setModal((prev: modalProps) => ({ ...prev, mataConnect: true }));
    else setModal((prev: modalProps) => ({ ...prev, infoConnect: !prev.infoConnect }));
  };

  const networkOnClick = () => {
    setNetworkSel((prev: networkModalProps) => ({ ...prev, modal: !networkSel.modal }));
  };

  const metaOnClick = async () => {
    const account = await connect();
    if (account) {
      comContext?.actions.setCom({ account });
      setModal((prev: modalProps) => ({ ...prev, mataConnect: false }));
    }
  };

  const logOutOnClick = () => {
    setModal((prev: modalProps) => ({ ...prev, infoConnect: false }));
    dis();
    comContext?.actions.setCom({ account: "" });
  };

  const networkBtnOnClick = async (e: React.ChangeEvent<HTMLButtonElement>) => {
    change(e.target.id);

    setNetworkSel({ title: e.target.id === "Goerli" ? "Ethereum Goerli" : "Arbitrum Goerli", modal: false });
  };

  useEffect(() => {
    window.addEventListener("resize", () => {
      setWidthResize(window.innerWidth);
    });
    return () => window.removeEventListener("resize", () => {});
  }, []);

  useEffect(() => {
    if (window.ethereum) {
      window.ethereum.on("networkChanged", (e: string) => {
        if (e === "421613") {
          setNetworkSel({ title: "Arbitrum Goerli", modal: false });
        } else {
          setNetworkSel({ title: "Ethereum Goerli", modal: false });
        }
      });
      setTimeout(() => {
        setNetworkSel({
          modal: false,
          title: window.ethereum.chainId === "0x66eed" ? "Arbitrum Goerli" : "Ethereum Goerli",
        });
      }, 50);
    }
  }, []);

  return (
    <>
      {modal.mataConnect && comContext?.state.account === "" && (
        <ConnectMetaModal onClick={metaOnClick} modalOnClick={() => setModal((prev: modalProps) => ({ ...prev, mataConnect: false }))} />
      )}
      <header style={{ position: "fixed", width: "100%", top: "0", left: "0", zIndex: "100" }}>
        <div
          css={css`
            ${defaultHeaderStyle}
          `}
        >
          <div
            css={css`
              ${maxWidthStyle}
            `}
          >
            <div
              css={css`
                ${flexCenter}
              `}
              style={{ padding: "0 10px" }}
            >
              <LinkTag link="/finance">
                {widthResize < 1024 && <OnlyLogo style={{ width: "35px", height: "35px", cursor: "pointer" }} />}
                {widthResize > 1024 && <Logo style={{ width: "250px", height: "50px", cursor: "pointer" }} />}
              </LinkTag>
            </div>
            {widthResize > 1024 && <HeaderItems setModal={setNavModal} />}
            <div style={{ display: "flex" }}>
              {comContext?.state.account !== "" && (
                <div style={{ height: "50px", position: "relative" }}>
                  <NetworkBtn onClick={networkOnClick} state={networkSel.modal} title={widthResize < 1024 ? "Network" : networkSel.title} />
                  {networkSel.modal && (
                    <NetworkModal style={{ width: "200px" }}>
                      <div style={{ display: "flex", flexDirection: "column" }}>
                        <Button id="Goerli" onClick={networkBtnOnClick} title="Ethereum Goerli" style={{ width: "100%", padding: "20px" }} />
                        <Button id="Arbitrum" onClick={networkBtnOnClick} title="Arbitrum Goerli" style={{ width: "100%", padding: "20px" }} />
                      </div>
                    </NetworkModal>
                  )}
                </div>
              )}
              <div style={{ height: "50px", position: "relative" }}>
                <ConnectBtn onClick={connectOnClick} state={modal} />
                {modal.infoConnect && <AccountModal setModal={setModal} logOutOnClick={logOutOnClick} />}
              </div>
            </div>

            {widthResize < 1024 && <Button title="Menu" addStyle={btnStyle} onClick={() => setNavModal(true)} />}
            {navModal && <HeaderItems setModal={setNavModal} />}
          </div>
        </div>
      </header>
    </>
  );
};

export default Header;
