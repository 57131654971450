import { css } from "@emotion/react";
import React, { useEffect } from "react";
import Content from "../Components/Atoms/Content";
import Detail from "../Components/Atoms/Detail";
import ConnectWallet from "../Components/Molecules/ConnectWallet";
import useOTC, { OtcCreateType } from "../Hooks/useOtc";
import { fontBoldStyle, palette } from "../Styles/theme";
import { shortAddress } from "../Utils/util";
import { PageType } from "./OtcToken";
import { ReactComponent as EtherScan } from "../Assets/etherScan.svg";

interface Iprops {
  setTab: React.Dispatch<React.SetStateAction<string>>;
  setOtcPage: React.Dispatch<React.SetStateAction<number>>;
}
const defaultSyle = `
  padding: 10px 0;
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

const OtcComplete: React.FC<Iprops> = ({ setTab, setOtcPage }) => {
  const { otcState, saveFn } = useOTC();

  const doneOnclick = async (e: React.ChangeEvent<HTMLButtonElement>) => {
    setTab("start");
    setOtcPage(PageType.FIND_PAGE);
  };
  /*
  useEffect(() => {
    if (otcState.status === OtcCreateType.ALREADY) {
      setTab("pending");
      setOtcPage(PageType.DEPOSIT_PAGE);
    }
    const timeId = setInterval(() => {
      saveFn("Find");
    }, 3000);
    return () => clearInterval(timeId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [otcState]);
*/
  return (
    <>
      <div style={{ margin: "40px 0" }} />
      <Content addStyle="padding: 20px;">
        <Detail title="Other User" content={otcState.toResult === false ? "Deposit Holding" : "Deposit Success!"} rightAddStyle={`color:${palette.gray};`} />
        <Detail title="Other User Account" content={shortAddress(otcState.toAccount)} />
        <div
          css={css`
            ${defaultSyle}
          `}
        >
          <span
            css={css`
              ${fontBoldStyle}
            `}
          >
            Tx Hash
          </span>

          <a
            style={{ textDecoration: "none", color: "green", display: "flex", alignItems: "center" }}
            href={
              window.ethereum.chainId === "0x66eed" ? `https://goerli.arbiscan.io/tx/${otcState.txHash}` : `https://goerli.etherscan.io/tx/${otcState.txHash}`
            }
            target="_blank"
            rel="noreferrer noopener"
          >
            <EtherScan style={{ color: "white", padding: "0 5px", width: "24px", height: "24px" }} />
            {shortAddress(otcState.txHash)}
          </a>
        </div>

        <div style={{ display: "flex", width: "100%", justifyContent: "center", boxSizing: "border-box", textAlign: "center", padding: "20px 30px" }}>
          <ConnectWallet disabled={false} onClick={doneOnclick} title="Done" style={{ backgroundColor: palette.blue200 }} />
        </div>
      </Content>
    </>
  );
};

export default OtcComplete;
