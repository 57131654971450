import React, { useEffect, useState } from "react";

import CommAccount from "../Models/ComData";

const commAccount = new CommAccount();

function useConnect() {
  const [comState, setComState] = useState(commAccount.accountRead() + "");

  if (typeof window.ethereum !== "undefined") {
    window.ethereum.on("accountsChanged", (code: any, reason: string) => {
      const accountSwitch = code[0];
      if (!accountSwitch) {
        setComState("");
      }
    });
  }

  const connect = async () => {
    if (typeof window.ethereum !== "undefined") {
      const account = await commAccount.connectContract();

      if (account) setComState(account);
      return account;
    }
  };

  const dis = async () => {
    commAccount.providerContract();
    setComState("");
  };

  return { connect, dis, comState };
}

export default useConnect;
