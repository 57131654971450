export const API_BASE_URL = `https://api-goerli.etherscan.io/api?module=logs&action=getLogs&address=`;
export const API_ETHERS_SCAN_URL = `${process.env.REACT_APP_OTC_CONTRACT}&apikey=${process.env.REACT_APP_ETHERS_API_KEY}`;

export const API_ARBITRUM_BASE_URL = `https://api-goerli.arbiscan.io/api?module=logs&action=getLogs&address=`;
export const API_ARBITRUM_ETHERS_SCAN_URL = `${process.env.REACT_APP_ARBITRUM_OTC_CONTRACT}&apikey=${process.env.REACT_APP_ARBITRUM_ETHERS_API_KEY}`;

export const fetcher = async (url: string) => {
  const res = await fetch(url, {
    method: "GET",
    headers: {
      Accept: "application/json",
    },
  });

  if (!res.ok) {
    throw res.status;
  }

  return await res.json();
};

export const dashboardfetcher = async (url: string) => {
  const res = await fetch(url, {
    method: "GET",
    headers: {
      Accept: "application/json",
      apiKey: "3sc",
    },
  });

  if (!res.ok) {
    throw res.status;
  }
  return await res.json();
};
