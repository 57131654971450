import { css } from "@emotion/react";
import React from "react";
import { fontBoldStyle } from "../../Styles/theme";

export const msgStyle = `
    ${fontBoldStyle}
    color: red;
`;

interface Iprops {
  state: boolean;
  title: string;
}

const WarningMsg: React.FC<Iprops> = ({ state, title }) => {
  return (
    <>
      {state && (
        <div
          css={css`
            ${msgStyle}
          `}
        >
          {title}
        </div>
      )}
    </>
  );
};

export default WarningMsg;
