import { css } from "@emotion/react";
import React, { useEffect, useState } from "react";
import ComContext, { IComProps } from "../Context/Com";
import ConnectMetaModal from "../Components/Molecules/ConnectMetaModal";
import useConnect from "../Hooks/useConnect";
import { flexCenter, fontSizePalette, fontThinStyle, palette } from "../Styles/theme";
import Content from "../Components/Atoms/Content";
import ContentBox from "../Components/Atoms/ContentBox";
import Input from "../Components/Atoms/Input";
import PerCentButtons from "../Components/Molecules/PerCentButtons";
import Detail from "../Components/Atoms/Detail";
import { ReactComponent as DownArrow } from "../../src/Assets/downArrow.svg";
import ContentFrame from "../Components/Atoms/ContentFrame";
import useBurn from "../Hooks/useBurn";
import { numDigit } from "../Utils/util";
import FeatureBtn from "../Components/Organisms/FeatureBtn";
import Prepare from "../Components/Atoms/Prepare";
import { toast } from "react-toastify";
import WarningMsg from "../Components/Atoms/WarningMsg";
import { waringProps } from "../Components/Organisms/FeverStaking";

const Burn = () => {
  const comContext = React.useContext<IComProps | null>(ComContext);
  const [connectMetaModal, setConnectMetaModal] = useState<boolean>(false);
  const { burnState, approve, convert } = useBurn();
  const [amount, setAmount] = useState<number>(0);
  const [stAmount, setStAmount] = useState<number>(0);
  const [clickNum, setClickNum] = useState<string>("");

  const [isMinWaring, setMinWaring] = useState<waringProps>({
    state: false,
    title: "",
  });

  const [process, setProcess] = useState({
    approveResult: false,
    target: "",
    loading: false,
    title: "",
  });

  const { connect } = useConnect();

  const connectOnClick = () => setConnectMetaModal(!connectMetaModal);

  const metaOnClick = async () => {
    const account = await connect();
    if (account) {
      comContext?.actions.setCom({ account });
      setConnectMetaModal(false);
    }
  };
  useEffect(() => {
    if (comContext?.state.account === "") {
      setAmount(0);
    } else setProcess((prev: any) => ({ ...prev, title: "Approve" }));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [comContext?.state.account]);

  useEffect(() => {
    setAmount(+burnState.myGen * (Number(clickNum) / 100));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clickNum]);

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => setAmount(+e.target.value > 0 ? +e.target.value : 0);

  useEffect(() => {
    setStAmount(amount * 0.01);
  }, [amount]);

  const burnOnClick = async (e: React.ChangeEvent<HTMLButtonElement>) => {
    if (amount === 0) {
      setMinWaring({ state: true, title: "Please check the amount" });
    } else {
      setProcess((prev: any) => ({ ...prev, loading: true }));
      if (!process.approveResult) {
        const res = await approve(amount);
        if (res) {
          toast.success("Transaction successful!");
          setProcess((prev: any) => ({ ...prev, approveResult: true, title: "Burn", loading: false }));
        } else {
          setProcess((prev: any) => ({ ...prev, approveResult: false, title: "Approve", loading: false }));
          toast.error("Transaction failed");
        }
      } else if (process.approveResult) {
        const res = await convert();
        if (res) {
          setAmount(0);
          setProcess((prev: any) => ({ ...prev, approveResult: false, title: "Approve", loading: false }));
          toast.success("Transaction successful!");
        } else {
          setProcess((prev: any) => ({ ...prev, approveResult: true, title: "Burn", loading: false }));
          toast.error("Transaction failed");
        }
      } else setProcess((prev: any) => ({ ...prev, loading: false }));
    }
  };
  return (
    <>
      {connectMetaModal && comContext?.state.account === "" && <ConnectMetaModal onClick={metaOnClick} modalOnClick={() => setConnectMetaModal(false)} />}
      <ContentFrame
        addStyle="display: flex;
            flex-direction: column;
            align-items: center;
            "
      >
        {window.ethereum.chainId !== "0x66eed" ? (
          <div style={{ width: "100%", height: "100%", display: "flex", alignItems: "center", justifyContent: "center" }}>
            <Prepare />
          </div>
        ) : (
          <Content
            style={{
              marginTop: "100px",
              display: "flex",
              width: "100%",

              flexDirection: "column",
              boxSizing: "border-box",
              alignItems: "start",
              justifyContent: "start",
              padding: "40px",
            }}
          >
            <Detail title="From" content={`Wallet Balance ${numDigit(burnState.myGen + "")} GEN`} rightAddStyle={`color:${palette.gray};`} />
            <ContentBox name="GEN">
              <Input
                disabled={comContext?.state.account === "" || process.loading}
                fontSize={fontSizePalette.base}
                type="Number"
                onChange={onChange}
                value={amount}
              />
            </ContentBox>
            <PerCentButtons inputNum={setClickNum} style={{ padding: "0px 30px" }} />

            <Detail
              title="Conversion rate"
              content={`Wallet Balance ${numDigit(burnState.myStGen + "")} stGEN`}
              leftAddStyle={`color:${palette.gray}; ${fontThinStyle};`}
              rightAddStyle={`color:${palette.gray};`}
              style={{ margin: "20px 0" }}
            />

            <div
              css={css`
                ${flexCenter}
                width:100%;
                margin: 20px 0;
              `}
            >
              <DownArrow />
            </div>

            <Detail title="To" content="1 Gen = 0.01 stGen" rightAddStyle={`color:${palette.gray};`} />

            <ContentBox name="stGEN">
              <Input disabled={true} fontSize={fontSizePalette.base} type="Number" value={stAmount} />
            </ContentBox>

            <FeatureBtn loading={process.loading} title={process.title} connectOnClick={connectOnClick} featureOnClick={burnOnClick} />
            <div style={{ display: "flex", justifyContent: "center", width: "100%" }}>
              <WarningMsg state={isMinWaring.state} title={isMinWaring.title} />
            </div>
          </Content>
        )}
      </ContentFrame>
    </>
  );
};

export default Burn;
