export const shortAddress = (address: string) => {
  return address.slice(0, 6) + "..." + address.slice(address.length - 4, address.length);
};

export const numDigit = (num: string) => {
  return Number(num).toFixed(8);
  //.replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
};

export const lowerCase = (contract: string) => {
  return (contract.split("0x")[1] + "").toLowerCase();
};
