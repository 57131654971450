import React from "react";
import { palette } from "../../Styles/theme";

interface Iprops {
  pos: string;
  color: string;
}

const ProcessItem: React.FC<Iprops> = ({ pos, color }) => {
  return (
    <>
      {[32, 26, 16].map((data, index) => (
        <div
          key={index}
          style={{
            left: `${pos === "left" ? `-${data / 2}px` : pos === "center" ? 0 : "unset"}`,
            right: `${pos === "right" ? `-${data / 2}px` : pos === "center" ? 0 : "unset"}`,
            margin: `${pos === "center" ? `auto` : "0"}`,
            position: "absolute",
            width: `${data}px`,
            height: `${data}px`,
            borderRadius: `${data / 2}px`,
            backgroundColor: `${index === 1 ? palette.blue300 : color}`,
          }}
        />
      ))}
    </>
  );
};

export default ProcessItem;
