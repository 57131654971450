import { ethers } from "ethers";
import { API_ARBITRUM_BASE_URL, API_ARBITRUM_ETHERS_SCAN_URL, API_BASE_URL, API_ETHERS_SCAN_URL, fetcher } from "../Apis/api";
import { ERC20_ABI } from "../Types/abi/ERC20";
import { OTC_ABI } from "../Types/abi/OtcAbi";
import { SEARCH_ABI } from "../Types/abi/SearchAbi";
import { lowerCase } from "../Utils/util";

export interface IOtcState {
  account: string;
  sendStatus: number | null;
  toAccount: string;
  fromAmount: number;
  toAmount: number;
  connect: boolean;
  fromToken: string | null;
  toToken: string | null;
  fromTokenContract: string | null;
  toTokenContract: string | null;
  toResult: boolean;
  fromResult: boolean;
  status: number;
  txHash: string;
}

export interface ICompleteOTCState {
  account0: string | null;
  account1: string | null;
  token0: string | null;
  token1: string | null;
  _amount0: string | null;
  _amount1: string | null;
}

export interface ICompleteState {
  //단위 확인해봐야하맘
  fromAccount: string;
  toAccount: string;
  fromToken: string;
  toTotken: string;
  fromAmount: number; //
  toAmount: number; //
  sendStaus: string;
}
export enum OtcTranseType {
  PREPARE = 0,
  ALREADY,
  COMPLEATE,
  CANCEL,
}
export const otcCompleteInit = {
  fromAccount: "",
  toAccount: "",
  fromToken: "",
  toTotken: "",
  fromAmount: 0,
  toAmount: 0,
  sendStaus: "",
};

export const otcInitial = {
  account: "",
  sendStatus: 0,
  toAccount: "",
  fromAmount: 0,
  toAmount: 0,
  connect: false,
  fromToken: "",
  toToken: "",
  fromTokenContract: "",
  toTokenContract: "",
  toResult: false,
  fromResult: false,
  status: 0,
  txHash: "",
};
const otcComInit = {
  account0: "",
  account1: "",
  token0: "",
  token1: "",
  _amount0: "",
  _amount1: "",
};

class OtcContract {
  provider: ethers.providers.Web3Provider | null;
  otcContract: ethers.Contract | null;
  account: string | null;
  complete: object | null;
  otcState: IOtcState;
  completeState: ICompleteOTCState;
  completePage: number;

  constructor() {
    this.provider = null;
    this.otcContract = null;
    this.account = null;
    this.complete = null;
    this.otcState = otcInitial;
    this.completeState = otcComInit;
    this.completePage = 0;
  }
  async connectContract() {
    this.provider = new ethers.providers.Web3Provider(window.ethereum);
    this.otcContract = new ethers.Contract(
      window.ethereum.chainId === "0x66eed" ? process.env.REACT_APP_ARBITRUM_OTC_CONTRACT + "" : process.env.REACT_APP_OTC_CONTRACT + "",
      OTC_ABI,
      this.provider.getSigner()
    );

    this.account = (await this.provider.send("eth_requestAccounts", []))[0];
  }

  async otcCompleteFn(flag: boolean) {
    // &fromBlock=${this.completePage}

    const dataEthers = await fetcher(`${API_ARBITRUM_BASE_URL}${API_ARBITRUM_ETHERS_SCAN_URL}&toBlock=latest`);

    if (dataEthers) {
      //this.completePage = parseInt(dataEthers.result.reverse()[10].blockNumber, 16);
      //console.log("data", dataEthers.result);
      //return;
      dataEthers.result.reverse().find((e: any, index: number) => {
        if (e.topics.length === 3) {
          const hexAmount = ethers.utils.parseUnits(this.otcState.toAmount + "", 18)._hex.split("0x")[1];
          const hexAmount1 = ethers.utils.parseUnits(this.otcState.fromAmount + "", 18)._hex.split("0x")[1];

          const fromContract = (this.otcState.fromTokenContract + "").split("0x")[1].toLocaleLowerCase();
          const toContract = (this.otcState.toTokenContract + "").split("0x")[1].toLocaleLowerCase();

          const hashAccount = !flag
            ? (this.otcState.account + "").split("0x")[1].toLocaleLowerCase()
            : (this.otcState.toAccount + "").split("0x")[1].toLocaleLowerCase();
          const hashToAccount = flag
            ? (this.otcState.account + "").split("0x")[1].toLocaleLowerCase()
            : (this.otcState.toAccount + "").split("0x")[1].toLocaleLowerCase();

          const token1data = e.data.substr(26, 40);
          const token2data = e.data.substr(90, 40);
          const amount1data = !flag ? e.data.substr(176, 18) : e.data.substr(240, 18);
          const amount2data = flag ? e.data.substr(176, 18) : e.data.substr(240, 18);

          const endState = e.data.substr(321);
          /*console.log(index, endState, e.data.substr(318));
          console.log(index, "hexAmount1", hexAmount1, amount2data);
          console.log(index, "hexAmount", hexAmount, amount1data);
          console.log(index, "fromContract", fromContract, token1data);
          console.log(index, "toContract", toContract, token2data);
          console.log(index, "hashAccount", e.topics[1], hashAccount);
          console.log(index, "hashToAccount", e.topics[2]);
          console.log(index, "this.otcState.txHash", this.otcState.txHash);*/
          if (
            endState === "2" &&
            amount2data.includes(hexAmount1) &&
            amount1data.includes(hexAmount) &&
            fromContract === token1data &&
            toContract === token2data &&
            e.topics[1].includes(hashAccount) &&
            e.topics[2].includes(hashToAccount) &&
            this.otcState.txHash === ""
          ) {
            this.otcState.txHash = e.transactionHash;
          }
        }
        return e;
      });
      return true;
    }
  }

  async getOtc(toAccount: string) {
    try {
      this.otcState.toAccount = toAccount;
      const data = await this.otcContract?.getOtcInfo(this.account + "", toAccount + "");
      //console.log("data", data);
      const ownAccount = lowerCase(this.account + "");
      this.otcState.status = data[1];
      if (data[1] === OtcTranseType.ALREADY) {
        if (ownAccount === lowerCase(data[6])) {
          this.otcState.fromTokenContract = data[3];
          this.otcState.toAmount = +ethers.utils.formatEther(data[8]);
          this.otcState.toResult = data[5];
          this.otcState.fromToken = await this.search(data[3]);

          this.otcState.account = data[6];
          this.otcState.toTokenContract = data[7];
          this.otcState.fromAmount = +ethers.utils.formatEther(data[4]);
          this.otcState.fromResult = data[9];
          this.otcState.toToken = await this.search(data[7]);
        } else if (ownAccount === lowerCase(data[2])) {
          this.otcState.fromTokenContract = data[7];
          this.otcState.toAmount = +ethers.utils.formatEther(data[4]);
          this.otcState.toResult = data[9];
          this.otcState.fromToken = await this.search(data[7]);

          this.otcState.account = data[2];
          this.otcState.toTokenContract = data[3];
          this.otcState.fromAmount = +ethers.utils.formatEther(data[8]);
          this.otcState.fromResult = data[5];
          this.otcState.toToken = await this.search(data[3]);
        }
      } else if (data[1] === OtcTranseType.COMPLEATE) {
        if (ownAccount === lowerCase(data[6])) {
          this.otcState.toTokenContract = data[3];
          //this.otcState.toAmount = +ethers.utils.formatEther(data[8]);
          this.otcState.toResult = true;
          this.otcState.toToken = await this.search(data[3]);

          this.otcState.account = data[6];
          this.otcState.fromTokenContract = data[7];
          //this.otcState.fromAmount = +ethers.utils.formatEther(data[4]);
          this.otcState.fromResult = true;
          this.otcState.fromToken = await this.search(data[7]);
        } else if (ownAccount === lowerCase(data[2])) {
          this.otcState.toTokenContract = data[7];
          //this.otcState.toAmount = +ethers.utils.formatEther(data[4]);
          this.otcState.toResult = true;
          this.otcState.toToken = await this.search(data[7]);

          this.otcState.account = data[2];
          this.otcState.fromTokenContract = data[3];
          //this.otcState.fromAmount = +ethers.utils.formatEther(data[8]);
          this.otcState.fromResult = true;
          this.otcState.fromToken = await this.search(data[3]);
        }

        if (this.otcState.toAmount !== 0 && this.otcState.fromAmount !== 0) {
          await this.otcCompleteFn(ownAccount === lowerCase(data[6]) ? true : false);
        }
      }

      return this.otcState.status;
    } catch (e) {
      return 999;
    }
  }

  async createGetOtc(toAccount: string) {
    try {
      this.otcState.toAccount = toAccount;
      const data = await this.otcContract?.getOtcInfo(this.account + "", toAccount + "");

      const ownAccount = lowerCase(this.account + "");
      this.otcState.status = data[1];
      if (data[1] === OtcTranseType.ALREADY) {
        if (ownAccount === lowerCase(data[6])) {
          this.otcState.fromTokenContract = data[3];
          this.otcState.toAmount = +ethers.utils.formatEther(data[8]);
          this.otcState.toResult = data[5];
          this.otcState.fromToken = await this.search(data[3]);

          this.otcState.account = data[6];
          this.otcState.toTokenContract = data[7];
          this.otcState.fromAmount = +ethers.utils.formatEther(data[4]);
          this.otcState.fromResult = data[9];
          this.otcState.toToken = await this.search(data[7]);
        } else if (ownAccount === lowerCase(data[2])) {
          this.otcState.fromTokenContract = data[7];
          this.otcState.toAmount = +ethers.utils.formatEther(data[4]);
          this.otcState.toResult = data[9];
          this.otcState.fromToken = await this.search(data[7]);

          this.otcState.account = data[2];
          this.otcState.toTokenContract = data[3];
          this.otcState.fromAmount = +ethers.utils.formatEther(data[8]);
          this.otcState.fromResult = data[5];
          this.otcState.toToken = await this.search(data[3]);
        }
      }

      return this.otcState.status;
    } catch (e) {
      return 999;
    }
  }

  otcData(type: string) {
    if (this.otcState.toAccount !== "") {
      this.getOtc(this.otcState.toAccount + "");
      //if (type === "Find") this.getOtc(this.otcState.toAccount + "");
      //else if (type === "Create") this.createGetOtc(this.otcState.toAccount + "");
    }

    return this.otcState;
  }
  async createOtc(type: string, fromTokenContract: string, toTokenContract: string, fromAmount: number, toAmount: number) {
    try {
      const tx = await this.otcContract?.createOtc(
        "OTC_TYPE_TOKEN",
        this.otcState.toAccount,
        fromTokenContract,
        toTokenContract,
        ethers.utils.parseUnits(fromAmount + "", 18),
        ethers.utils.parseUnits(toAmount + "", 18)
      );
      const txwait = await tx.wait();
      if (txwait) {
        this.otcState.status = 1; // add
        this.otcState.fromResult = false;
        this.otcState.toResult = false;
        this.otcState.fromTokenContract = fromTokenContract;
        this.otcState.toTokenContract = toTokenContract;
        this.otcState.fromAmount = toAmount;
        this.otcState.toAmount = fromAmount;
        return true;
      }
    } catch (e) {
      return false;
    }
  }
  approveContract(contract: string) {
    if (this.provider !== null) {
      return new ethers.Contract(contract + "", ERC20_ABI, this.provider.getSigner());
    }
  }
  async cancelOtc() {
    try {
      const tx = await this.otcContract?.cancelOtc(this.otcState.account, this.otcState.toAccount);
      const txwait = await tx.wait();
      if (txwait) return true;
    } catch (e) {
      return false;
    }
  }
  async allownanceOtc() {
    try {
      if (this.provider !== null) {
        const contractt = new ethers.Contract(this.otcState.toTokenContract + "", ERC20_ABI, this.provider.getSigner());
        const res = await contractt?.allowance(
          this.otcState.account,
          window.ethereum.chainId === "0x66eed" ? process.env.REACT_APP_ARBITRUM_OTC_CONTRACT + "" : process.env.REACT_APP_OTC_CONTRACT + ""
        );

        if (res) return true;
      }
    } catch (e) {
      return false;
    }
  }

  async approveOtc() {
    try {
      const approveTx = await this.approveContract(this.otcState.toTokenContract + "")?.approve(
        window.ethereum.chainId === "0x66eed" ? process.env.REACT_APP_ARBITRUM_OTC_CONTRACT + "" : process.env.REACT_APP_OTC_CONTRACT + "",
        ethers.utils.parseUnits(this.otcState.toAmount + "", 18)
      );
      const txwait = await approveTx.wait();

      if (txwait) return true;
    } catch (e) {
      return false;
    }
  }
  async depositOtc() {
    try {
      const tx = await this.otcContract?.depositToken(
        this.otcState.account,
        this.otcState.toAccount,
        this.otcState.toTokenContract,
        ethers.utils.parseUnits(this.otcState.toAmount + "", 18)
      );
      const txwait = await tx.wait();
      if (txwait) return true;
    } catch (e) {
      return false;
    }
  }

  async search(contract: string) {
    try {
      if (this.provider !== null && contract !== "") {
        const data = new ethers.Contract(contract + "", SEARCH_ABI, this.provider.getSigner());
        const res = await data.symbol();
        if (res !== "") return res;
        else return "";
      }
    } catch (e) {
      return "error";
    }
  }
  async amount(contract: string) {
    try {
      if (this.provider !== null) {
        const contractAddress = new ethers.Contract(contract + "", ERC20_ABI, this.provider.getSigner());
        return Number(ethers.utils.formatEther(await contractAddress.balanceOf(this.account)));
      }
    } catch (e) {
      return 0;
    }
  }
}

export default OtcContract;
