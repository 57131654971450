import React from "react";
import { ethers } from "ethers";
interface IActions {
  setCom(value: any): void;
}

export interface IState {
  account: string;
  provider: ethers.providers.Web3Provider | null;
  genAmount: number;
  stGenAmount: number;
}

export interface IComProps {
  state: IState;
  actions: IActions;
}

const context = React.createContext<IComProps | null>(null);
const { Provider } = context;

class ComProvider extends React.Component<{ children: React.ReactNode }> {
  state: IState = {
    account: "",
    provider: null,
    genAmount: 0,
    stGenAmount: 0,
  };
  actions = {
    setCom: (value: IState): void => {
      this.setState((prev: IState) => {
        return { ...prev, ...value };
      });
    },
  };

  render() {
    const { state, actions } = this;
    const value = { state, actions };
    return <Provider value={value}>{this.props.children}</Provider>;
  }
}

export { ComProvider };
export default context;
