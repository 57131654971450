import { ethers } from "ethers";
import { ERC20_ABI } from "../Types/abi/ERC20";
import { GEN_STAKING_ABI } from "../Types/abi/StakingAbi";

export interface IStState {
  apr?: number;
  totalStaked: string;

  myStaked?: string;
  walletBalance: number;
  myGen: string;
  depositValue: number;
  reward: number;

  totalgenStaked: number;
  totalstgenStaked: number;

  genTotalStaked: number;
}

export const stInitial = {
  apr: 0,
  totalStaked: "",
  myStaked: "",
  walletBalance: 0,
  myGen: "",
  depositValue: 0,
  reward: 0,
  totalgenStaked: 0,
  totalstgenStaked: 0,
  genTotalStaked: 0,
};

class StakingContract {
  provider: ethers.providers.Web3Provider | null;
  stGen: ethers.Contract | null;
  gateKeeper: ethers.Contract | null;
  fever: ethers.Contract | null;
  gen: ethers.Contract | null;
  stDataState: IStState;
  account: string | null;

  constructor() {
    this.provider = null;
    this.stGen = null;
    this.gateKeeper = null;
    this.gen = null;
    this.fever = null;
    this.account = null;
    this.stDataState = stInitial;
  }

  async connectContract() {
    this.provider = new ethers.providers.Web3Provider(window.ethereum);
    this.account = (await this.provider.send("eth_requestAccounts", []))[0];
    this.gateKeeper = new ethers.Contract(
      window.ethereum.chainId === "0x66eed"
        ? process.env.REACT_APP_ARBITRUM_GATEKEEPER_STAKING_CONTRACT + ""
        : process.env.REACT_APP_GATEKEEPER_STAKING_CONTRACT + "",
      GEN_STAKING_ABI,
      this.provider.getSigner()
    );

    this.fever = new ethers.Contract(
      window.ethereum.chainId === "0x66eed" ? process.env.REACT_APP_ARBITRUM_FEVER_STAKING_CONTRACT + "" : process.env.REACT_APP_FEVER_STAKING_CONTRACT + "",
      GEN_STAKING_ABI,
      this.provider.getSigner()
    );
    this.gen = new ethers.Contract(
      window.ethereum.chainId === "0x66eed" ? process.env.REACT_APP_ARBITRUM_GEN_CONTRACT + "" : process.env.REACT_APP_GEN_TOKEN_CONTRACT + "",
      ERC20_ABI,
      this.provider.getSigner()
    );

    this.stGen = new ethers.Contract(
      window.ethereum.chainId === "0x66eed" ? process.env.REACT_APP_ARBITRUM_STGEN_TOKEN_CONTRACT + "" : process.env.REACT_APP_STGEN_TOKEN_CONTRACT + "",
      ERC20_ABI,
      this.provider.getSigner()
    );
    if (this.gateKeeper && this.gen) return true;
    return false;
  }
  async readGenStaking(token: string) {
    this.stDataState.apr = parseFloat(ethers.utils.formatEther(token === "gen" ? await this.gateKeeper?.apr() : await this.fever?.apr())) * 10000000000000000;

    /*this.stDataState.totalStaked = ethers.utils.formatEther(
      token === "gen"
        ? await this.gen?.balanceOf(
            window.ethereum.chainId === "0x66eed"
              ? process.env.REACT_APP_ARBITRUM_GATEKEEPER_STAKING_CONTRACT + ""
              : process.env.REACT_APP_GATEKEEPER_STAKING_CONTRACT + ""
          )
        : await this.stGen?.balanceOf(
            window.ethereum.chainId === "0x66eed"
              ? process.env.REACT_APP_ARBITRUM_FEVER_STAKING_CONTRACT + ""
              : process.env.REACT_APP_FEVER_STAKING_CONTRACT + ""
          )
    );*/
    //////
    this.provider = new ethers.providers.Web3Provider(window.ethereum);
    this.gateKeeper = new ethers.Contract(
      window.ethereum.chainId === "0x66eed"
        ? process.env.REACT_APP_ARBITRUM_GATEKEEPER_STAKING_CONTRACT + ""
        : process.env.REACT_APP_GATEKEEPER_STAKING_CONTRACT + "",
      GEN_STAKING_ABI,
      this.provider.getSigner()
    );
    this.fever = new ethers.Contract(
      window.ethereum.chainId === "0x66eed" ? process.env.REACT_APP_ARBITRUM_FEVER_STAKING_CONTRACT + "" : process.env.REACT_APP_FEVER_STAKING_CONTRACT + "",
      GEN_STAKING_ABI,
      this.provider.getSigner()
    );

    this.stDataState.totalStaked =
      token === "gen" ? ethers.utils.formatEther(await this.gateKeeper?.totalStaked()) : ethers.utils.formatEther(await this.fever?.totalStaked());

    //////

    this.stDataState.myStaked = ethers.utils.formatEther(
      token === "gen" ? await this.gateKeeper?.staked(this.account) : await this.fever?.staked(this.account)
    );
    this.stDataState.myGen = ethers.utils.formatEther(token === "gen" ? await this.gen?.balanceOf(this.account) : await this.stGen?.balanceOf(this.account)); //?
    this.stDataState.reward = Number(ethers.utils.formatEther(token === "gen" ? await this.gateKeeper?.estimateReward() : await this.fever?.estimateReward())); //?

    this.stDataState.genTotalStaked = await Number(ethers.utils.formatEther(await this.gateKeeper?.staked(this.account)));

    return this.stDataState;
  }

  async stakeData() {
    this.provider = new ethers.providers.Web3Provider(window.ethereum);
    this.gateKeeper = new ethers.Contract(
      window.ethereum.chainId === "0x66eed"
        ? process.env.REACT_APP_ARBITRUM_GATEKEEPER_STAKING_CONTRACT + ""
        : process.env.REACT_APP_GATEKEEPER_STAKING_CONTRACT + "",
      GEN_STAKING_ABI,
      this.provider.getSigner()
    );
    this.fever = new ethers.Contract(
      window.ethereum.chainId === "0x66eed" ? process.env.REACT_APP_ARBITRUM_FEVER_STAKING_CONTRACT + "" : process.env.REACT_APP_FEVER_STAKING_CONTRACT + "",
      GEN_STAKING_ABI,
      this.provider.getSigner()
    );

    this.stDataState.totalgenStaked = +ethers.utils.formatEther(await this.gateKeeper?.totalStaked());
    this.stDataState.totalstgenStaked = +ethers.utils.formatEther(await this.fever?.totalStaked());

    return this.stDataState;
  }

  async readFeverStaking() {
    this.stDataState.apr = parseFloat(ethers.utils.formatEther(await this.gateKeeper?.apr())) * 10000000000000000;

    this.stDataState.totalStaked = ethers.utils.formatEther(await this.gen?.balanceOf(process.env.REACT_APP_GATEKEEPER_STAKING_CONTRACT));

    this.stDataState.myStaked = ethers.utils.formatEther(await this.gateKeeper?.staked(this.account));
    this.stDataState.myGen = ethers.utils.formatEther(await this.gen?.balanceOf(this.account));

    this.stDataState.reward = Number(ethers.utils.formatEther(await this.gateKeeper?.estimateReward()));

    return this.stDataState;
  }

  async approveGen(amount: number) {
    this.stDataState.depositValue = amount;

    try {
      const convertedNum = ethers.utils.parseUnits(this.stDataState.depositValue + "", 18);

      const increaseApproveTx = await this.gen?.increaseAllowance(
        window.ethereum.chainId === "0x66eed"
          ? process.env.REACT_APP_ARBITRUM_GATEKEEPER_STAKING_CONTRACT + ""
          : process.env.REACT_APP_GATEKEEPER_STAKING_CONTRACT + "",
        convertedNum
      );
      const transferReceipt = await increaseApproveTx.wait();
      if (transferReceipt) return true;
    } catch (error) {
      return false;
    }
  }

  async approveFever(amount: number) {
    this.stDataState.depositValue = amount;
    try {
      const convertedNum = ethers.utils.parseUnits(this.stDataState.depositValue + "", 18);
      const increaseApproveTx = await this.stGen?.increaseAllowance(
        window.ethereum.chainId === "0x66eed" ? process.env.REACT_APP_ARBITRUM_FEVER_STAKING_CONTRACT + "" : process.env.REACT_APP_FEVER_STAKING_CONTRACT + "",
        convertedNum
      );
      const transferReceipt = await increaseApproveTx.wait();
      if (transferReceipt) return true;
    } catch (error) {
      return false;
    }
  }
  async stakeGen() {
    try {
      const convertedNum = ethers.utils.parseUnits(this.stDataState.depositValue + "", 18);

      const gatekeeperStakeTx = await this.gateKeeper?.stake(convertedNum);
      const transferReceipt = await gatekeeperStakeTx.wait();
      if (transferReceipt) {
        //성공 후 초기화
        this.stDataState.depositValue = 0;
        return true;
      }
    } catch (error) {
      return false;
    }
  }
  async stakeFever() {
    try {
      const convertedNum = ethers.utils.parseUnits(this.stDataState.depositValue + "", 18);
      const gatekeeperStakeTx = await this.fever?.stake(convertedNum);
      const transferReceipt = await gatekeeperStakeTx.wait();
      if (transferReceipt) {
        //성공 후 초기화
        this.stDataState.depositValue = 0;
        return true;
      }
    } catch (error) {
      return false;
    }
  }
  async unstake(amount: number) {
    try {
      const convertedNum = ethers.utils.parseUnits(amount + "", 18);
      const gatekeeperStakeTx = await this.gateKeeper?.unstake(convertedNum);
      const transferReceipt = await gatekeeperStakeTx.wait();
      if (transferReceipt) {
        //성공 후 초기화
        this.stDataState.depositValue = 0;
        return true;
      }
    } catch (error) {
      return false;
    }
  }

  async claimGen() {
    try {
      const gateKeeperClaimTx = await this.gateKeeper?.claim();

      if (await gateKeeperClaimTx.wait()) return true;
    } catch (error) {
      return false;
    }
  }

  async claimFever() {
    try {
      const feverClaimTx = await this.fever?.claim();

      if (await feverClaimTx.wait()) return true;
    } catch (error) {
      return false;
    }
  }

  gateKeeperContract() {
    return this.gateKeeper;
  }
  genContract() {
    return this.gen;
  }
  stData() {
    return this.stDataState;
  }
}

export default StakingContract;
