import { css } from "@emotion/react";
import React from "react";
import { flexCenter, fontSizePalette, palette } from "../../Styles/theme";

const defaultStyle = `
    ${flexCenter}
    width: 400px;
    height: 400px;
    color:${palette.blue200};
    border-radius: 20px;
    border-width:2px;
    border-style: solid;  
`;

const Prepare = () => {
  return (
    <div
      css={css`
        ${defaultStyle}
        ${fontSizePalette.lg}
      `}
    >
      Coming Soon...
    </div>
  );
};

export default Prepare;
