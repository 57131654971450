import React, { useState } from "react";
import { flexCenter, fontBoldStyle, fontSizePalette, palette } from "../../Styles/theme";
import ContentBox from "../Atoms/ContentBox";
import Input from "../Atoms/Input";
import { ReactComponent as DownTriangle } from "../../../src/Assets/downTriangle.svg";
import { css } from "@emotion/react";
import Modal from "../Atoms/Modal";
import { ReactComponent as Close } from "../../Assets/close.svg";

const btnStyle = `
    all: unset;
    wdith: 100%;
    padding: 10px 0;
    cursor:pointer;
    &:hover{
        background-color:#061753;
    }
`;

// background: linear-gradient(180deg, rgba(33, 33, 33, 0.3) 0%, rgba(31, 38, 67, 0.3) 38.81%, rgba(25, 52, 152, 0.3) 100%);
const defaultStyle = `
  position: relative;
  width: 540px;
  height: 300px;
  ${flexCenter}
  border-radius: 20px;
  
  box-shadow: 6px 17px 33px 5px rgba(0, 0, 0, 0.25);
  color: white;
`;

interface Iprops {
  modalOnClick: () => void;
  title: string;
}

const DropDown: React.FC<Iprops> = ({ modalOnClick, title }) => {
  //const [title, setTitle] = useState<string>("Select Assets");

  const svgTag = () => {
    return <DownTriangle />;
  };

  return (
    <div style={{ position: "relative", width: "100%", height: "100%" }}>
      <ContentBox id="Sell" rightTag={svgTag()} onClick={modalOnClick}>
        <Input
          style={{ boxSizing: "border-box", cursor: "pointer", border: `2px solid ${palette.blue200}` }}
          disabled={true}
          fontSize={fontSizePalette.base}
          value={title}
        />
      </ContentBox>
    </div>
  );
};

export default DropDown;
