export const palette = {
  mint: "#00E5B2",
  sky: "#04D1CD",
  gray: "#B4B4B4",
  blue100: "#4281E9",
  blue200: "#3956D9",
  blue300: "#0A1C5E",
  blue400: "#0F236B",
};

export const fontSizePalette = {
  xs: "font-size : 13px",
  sm: "font-size : 16px",
  base: "font-size : 20px",
  lg: "font-size : 25px",
  xxl: "font-size : 45px",
};

export const fontBoldStyle = `
  font-family : Montserrat;
  font-style : normal;
  font-weight: 700;
`;
export const fontThinStyle = `
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
`;

export const fontSmThinStyle = `
  font-family: Montserrat;
  font-style: normal;
  font-weight: 300;
`;

export const flexCenter = `
  
  display: flex;
  justify-content:center;
  align-items: center;
  box-sizing: border-box;
`;

export const hoverStyle = `
&:hover{
  opacity: 0.9;
}
`;

export const noneHoverStyle = `
&:hover{
  opacity: 1;
}
`;
